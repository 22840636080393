// import faker from 'faker';

import "./style.css";
import "./App.css";

import { useEffect, useRef } from "react";
import http from "./http-commons";
import _ from "lodash";
// ".../" when parameter is being passed in url, otherwise ""

export const notmandatory = Object.freeze({
  scale: true,
  "time signature": true,
  "if any other mood, please mention here": true,
  music_theory: true,
  region: true,
  lflag: true,
  "length(number of bars)": true,
  "mood intensity(1 - lowest, 5 - highest)": true,
  "frequency range": true,
  "activity_theme": true,
});

export const notmandatory_ifloops = Object.freeze({
  "recommended section": true,
  "activity_theme": true,

});

export const fieldIsMandatory = (field, rowIsLoop) => {
  if (field in notmandatory) {
    return false;
  } else if (field in notmandatory_ifloops && rowIsLoop) {
    return false;
  }
  return true;
};

export const rowIsEmptyAndMandatory = (row, feild) => {
  return (row[feild] === "" || row[feild] === null)
    && fieldIsMandatory(feild, row["audio type"] === "loops")
    && !(row["instrument role"] === "fx" || row["instrument role"] === "sfx");
}

export const API = Object.freeze({
  BASE_URL: http.defaults.baseURL,
  FILE_UPLOAD: "/file-upload",
  SUBMIT: "/submission-form",
  TASK_STATUS: "/api/status/",
  GET_AUDIO: "/api/get-audio/",
  PROGRESS: http.defaults.baseURL + "/api/progress",
  GET_USER_INFO: "/api/get-user-info/",
  SAVE_CSV_CHECKPOINT: "/api/save-csv-checkpoint",
  EXPORT_CSV: "/api/export-csv",
  IMPORT_CSV: "/api/import-csv",
  GET_CSV_CHECKPOINT: "/api/get-csv-checkpoint",
  APPEND: "/api/update/append",
  REPLACE: "/api/update/replace",
  DELETE_SUBMISSION: "/api/delete-submission"
});

export async function upload(
  api,
  file,
  email,
  submissionId,
  defaults,
  totalLength,
  path = "",
  originalLength = 0
) {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("userEmail", email);
  formData.append("submissionId", submissionId);
  formData.append("submissionName", defaults.submissionName);
  formData.append("defaults", JSON.stringify(defaults));
  formData.append("totalLength", totalLength);
  formData.append("path", path);
  formData.append("originalLength", originalLength);
  let response = await http.post(api, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
}

export function useInterval(callback, delay, stopFlag) {
  const savedCallback = useRef();
  //Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id;
    function tick() {
      savedCallback.current();
      if (stopFlag) {
        clearInterval(id);
      }
    }
    if (delay !== null && !stopFlag) {
      id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  });
}

export function shortId() {
  return "_" + Math.random().toString(36).substr(2, 9);
}

export function randomColor() {
  return `hsl(${Math.floor(Math.random() * 360)}, 95%, 90%)`;
}

export function selectMaker(df, tag) {
  let uniq = _.uniqBy(df, tag);
  let optarr = uniq.map((lab) => {
    return {
      label: lab[tag],
      backgroundColor: randomColor(),
    };
  });

  return optarr;
}

export const ActionTypes = Object.freeze({
  ADD_OPTION_TO_COLUMN: "add_option_to_column",
  ADD_ROW: "add_row",
  UPDATE_COLUMN_TYPE: "update_column_type",
  UPDATE_COLUMN_HEADER: "update_column_header",
  UPDATE_CELL: "update_cell",
  ADD_COLUMN_TO_LEFT: "add_column_to_left",
  ADD_COLUMN_TO_RIGHT: "add_column_to_right",
  DELETE_COLUMN: "delete_column",
  ADD_ROWNEW: "add_rownew",
  UPDATE_ROW: "update_row",
  ENABLE_RESET: "enable_reset",
  IMPORT: "import",
});

export const DataTypes = Object.freeze({
  NUMBER: "number",
  TEXT: "text",
  SELECT: "select",
  STATIC: "static",
  STATIC_NUMBER: "static_number",
  MULTI_SELECT: "Multiple Tags",
});

// export const maxTagLength = 3;
export const maxTagLength = (id) => {
  if (id === "instrument role") {
    return 1;
  }
  return 3;
}
