import React from "react";
import Instructions from "./Instructions";
import StemInstructions from "./StemInstructions";

function CombinedInstructions() {
    return (
        <div
            style={{
                marginTop: "5%",
            }}
        >
            <Instructions />
            <StemInstructions />
        </div>
    );
}

export default CombinedInstructions;
