import { React, useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap/";
import { useLocation, useHistory } from "react-router-dom";
import http from "../http-commons";
import { API, useInterval, upload, rowIsEmptyAndMandatory } from "../utils";
import { useAuth0 } from "@auth0/auth0-react";
import "./CollectedInfo.css";
import EditableTable from "../TableUtils/EditableTable";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from 'react-tooltip';

// import Walkthrough from "./Walkthrough";

// import UpdateSub from "./UpdateSub"

function CollectedInfo() {
  const { user } = useAuth0();
  let history = useHistory();
  var st = "";
  st = useLocation();
  const submission_id = st.state.submissionId;
  const dat = JSON.parse(localStorage.getItem(submission_id) || "{}");

  const initTotalLength = dat.tl; // total time at first upload
  const taskId = dat.taskId;
  const submissionId = dat.submissionId;
  const submissionDefaults = dat.defaults;
  // const optional_fields = submissionDefaults.isStemSubmission === "true" ? notmandatory : { ...notmandatory, ...notmandatory_ifloops };
  // const [df, setDf] = useState(dat);

  const [df, _setDf] = useState(dat.df);

  const [saved, setSaved] = useState("unsaved");
  const [totalLength, setTotalLength] = useState(initTotalLength);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const inputFile = useRef();
  const [newDf, setNewDf] = useState();
  // const [autoSave, setAutoSave] = useState(300000);

  const sendjson = async (destination, df, submissionId) => {
    // let temp = df.data.map(
    //   (row) => {
    //     row.forEach((cell) => {
    //       if typeof cell === "array" {

    //     }
    //   }
    // )
    let jsondf = {
      submissionId: submissionId,
      userEmail: user.email,
      submissionName: submissionDefaults.submissionName,
      totalLength: totalLength,
      jsoncsv: df.data,
    };
    let response = await http.post(destination, jsondf, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  };

  const setDf = (df) => {
    localStorage.setItem(
      submission_id,
      JSON.stringify({ ...dat, df: { ...dat.df, data: df.data } })
    );
    _setDf(df);
  };

  const updatedf = (state) => {
    setDf(state);
    setSaved("unsaved");
  };

  const notify = (p, t = { autoClose: 5000 }) => toast(p, t);


  const reformat_field = (field) => {
    if (field === "activity_theme") {
      return "Description";
    }
    else {
      return field;
    }
  }

  const lflagErrors = (row) => {
    let lflagErrorCodes = [];
    if ((row["lflag"] === "File is silent, please use the replace button to replace the file." || row["audio type"] === "ERROR: silent")) {
      lflagErrorCodes.push("silent");
    }
    if ((row["lflag"].includes("Sample rate") || row["audio type"] === "ERROR: sample rate is not 44100")) {
      lflagErrorCodes.push("samplerate");
    }
    return lflagErrorCodes;
  };

  const recommendedSectionErrors = (row) => {
    let recommendedSectionErrors = [];
    if (submissionDefaults.isStemSubmission === "true") {
      if (row["recommended section"] === "" || row["recommended section"] === null) {
        recommendedSectionErrors.push(`Empty: ${reformat_field("recommended section")}`);
      }
      if (row["recommended section"] !== "") {
        let hasPartInStr = !row["recommended section"].includes("Part");
        let isStemFile = row["audio type"].includes("stem");
        let isMixOrMaster = row["recommended section"].includes("mix") || row["recommended section"].includes("master");
        if (hasPartInStr && isStemFile && !isMixOrMaster) {
          recommendedSectionErrors.push("Missing Part Name");
        }
      }
    }
    return recommendedSectionErrors;
  };


  const validateRow = (row) => {
    let errors = [];
    for (const feild in row) {

      const isRowEmptyAndMandatory = rowIsEmptyAndMandatory(row, feild);

      switch (feild) {

        case "lflag":
          let lflagErrorCodes = lflagErrors(row);
          lflagErrorCodes.forEach((errorCode) => {
            errors.push(errorCode);
          });
          break;

        case "recommended section":
          let recommendedSectionErrorsList = recommendedSectionErrors(row);
          recommendedSectionErrorsList.forEach((errorText) => {
            errors.push(errorText);
          });
          break;

        default:
          if (isRowEmptyAndMandatory) {
            errors.push(`Empty: ${reformat_field(feild)} column`);
          }
      }
    }
    return errors;
  }

  const ErrorNotification = (title, message) => {
    return (
      <div style={{ color: "black" }}>
        <div style={{ color: "black", fontWeight: "bolder" }}>{title}</div> <br />
        <span style={{ color: "Red", fontWeight: "bolder" }}>
          {message}
        </span><br />
        Click to dismiss this message.
      </div>
    );
  }


  const checkSubmission = (data) => {
    let isValid = true;
    data.forEach((row, index) => {
      try {
        let fillErrors = validateRow(row);
        if (fillErrors.length > 0) {
          let notified = false;
          if (fillErrors.includes("silent") && !notified) {
            notified = true;
            notify(
              ErrorNotification(`Row #${index + 1} ${row["filename"]}`, "Is a silent wav file, please check your export settings in your DAW and replace the file."),
              { autoClose: 20000000, timeOut: 0, extendedTimeOut: 0 }
            )
          }
          if (fillErrors.includes("samplerate") && !notified) {
            notified = true;
            notify(
              ErrorNotification(`Row #${index + 1} ${row["filename"]}`, "Sample rate is not 44100Hz, please replace with a 44100Hz sample"),
              { autoClose: 20000000, timeOut: 0, extendedTimeOut: 0 }
            )
          }
          if (fillErrors.includes("Missing Part Name") && !notified) {
            notified = true;
            notify(
              ErrorNotification(`Row #${index + 1} ${row["filename"]}`, "Missing Part Name (such as Part A, Part B in an AABA 32-bar form track) in Recommended Section column"),
              { autoClose: 20000000, timeOut: 0, extendedTimeOut: 0 }
            )
          }
          fillErrors.forEach((errorText) => {
            isValid = false;
            if (errorText !== "silent" && errorText !== "samplerate" && errorText !== "Missing Part Name") {
              notify(
                ErrorNotification(`Row #${index + 1} ${row["filename"]}`, errorText),
                { autoClose: 20000000, timeOut: 0, extendedTimeOut: 0 }
              );
            }
          })
        }
      } catch (error) {
        console.log(error);
        let notified = false;
        for (const feild in row) {
          const isRowEmptyAndMandatory = rowIsEmptyAndMandatory(row, feild);
          if ((row["lflag"] === "File is silent, please use the replace button to replace the file." || row["audio type"] === "ERROR: silent") && !notified) {
            notified = true;
            notify(
              ErrorNotification(`Row #${index + 1} ${row["filename"]}`, "Is a silent wav file, please check your export settings in your DAW and replace the file."),
              { autoClose: 20000000, timeOut: 0, extendedTimeOut: 0 }
            )
          }
          if ((row["lflag"].includes("Sample rate") || row["audio type"] === "ERROR: sample rate is not 44100") && !notified) {
            notified = true;
            notify(
              ErrorNotification(`Row #${index + 1} ${row["filename"]}`, "Sample rate is not 44100Hz, please replace with a 44100Hz sample"),
              { autoClose: 20000000, timeOut: 0, extendedTimeOut: 0 }
            )
          }
          if (isRowEmptyAndMandatory) {
            isValid = false;
            notify(
              ErrorNotification(`Row #${index + 1} ${row["filename"]}`, `Empty: ${reformat_field(feild)} column`),
              { autoClose: 20000000, timeOut: 0, extendedTimeOut: 0 }
            );
          }
        }
      }
    });
    return isValid;
  }

  const typage = () => {
    let isValid = checkSubmission(df.data);
    if (!isValid) {
      notify(<div>
        <span style={{ color: "red" }}>
          Please fill in all the required fields! (Fields marked with a asterisk are required) and replace any problematic files.
        </span>
      </div>);
      saveprogress("saved");
      notify(<div style={{ color: "black" }}>
        <span style={{ color: "black", fontWeight: "bolder" }}>Submission is not valid yet</span> <br />
        <span style={{ color: "Red", fontWeight: "bolder" }}>Please fill in all the required fields! (Fields marked with a asterisk are required) and replace any problematic files.</span> <br />
        If your samples are atonal, write <span style={{ color: "green", fontWeight: "bolder" }}>atonal</span> in the <span style={{ color: "green", fontWeight: "bolder" }}>key</span> section.
        <br />
        If your samples are non-rhythmic, in free tempo, write <span style={{ color: "green", fontWeight: "bolder" }}>non-rhythmic</span> in the <span style={{ color: "green", fontWeight: "bolder" }}>tempo and length</span> section.
        <br />
        Once they're filled in, the sample's index number <span style={{ color: "black", fontWeight: "bolder" }}>#</span> will turn
        <span style={{
          paddingBlock: "3px", paddingInline: "5px",
          marginInline: "3px", borderRadius: "5px",
          background: " linear-gradient(90deg,#11998e,#38ef7d)",
          color: "black"
        }}>green</span>. <br /> <br /> Once you're done with all of them, click <span style={{
          paddingBlock: "3px", paddingInline: "5px",
          marginInline: "3px", borderRadius: "5px",
          background: " -webkit-linear-gradient(230deg,#fc5c7d,#6a82fb)",
          color: "black"
        }}>
          Submit</span>
      </div>, { autoClose: 20000000, timeOut: 0, extendedTimeOut: 0 });
      return;
    }
    else {
      sendjson(API.SUBMIT, df, submissionId).then((response) => {
        console.log(response);
        notify("✔ Submission Successful");
        history.push("/submitmusic/thankyou");
      });
    };
  };

  const saveprogress = (msg) => {
    sendjson(API.SAVE_CSV_CHECKPOINT, df, submissionId).then((response) => {
      notify("✔ " + msg);
    }).catch((error) => {
      console.log(error);
      window.alert("Error in saving progress, contact us if this persists");
    });
    setSaved("saved");
  };

  useEffect(() => {
    if (("error_messages" in dat)) {
      dat.error_messages.forEach((msg) => {
        notify(<div>
          <span style={{ color: "red", fontWeight: "bolder" }}>{msg}</span>
          <br />
          <span style={{ color: "black" }}>Ignore if you've already fixed this issue</span>
        </div>, { autoClose: 20000000, timeOut: 0, extendedTimeOut: 0 });
      });
    }
    notify(<div style={{ color: "black" }}>
      Use the checkboxes to show or hide columns in the table to make it easier to navigate.
    </div>, { autoClose: 20000000, timeOut: 0, extendedTimeOut: 0 });
    notify(<div style={{ color: "black" }}>
      Hover your mouse over the column names in the table to view explanations and examples.
    </div>, { autoClose: 20000000, timeOut: 0, extendedTimeOut: 0 });
    notify("Click on a filename to quickly pause all playing audio", { autoClose: 20000, timeOut: 0, extendedTimeOut: 0 });
    notify(<div style={{ color: "black" }}>
      Remember to <span style={{ color: "green", fontWeight: "bolder" }}>save</span> your progress before submitting.
    </div>, { autoClose: 20000, timeOut: 0, extendedTimeOut: 0 });
  }, []);

  useInterval(
    () => {
      saveprogress("Autosaved (every 5 minutes)");
    },
    300000,
    saved === "saved"
  );

  const exportcsv = async (df, submissionId) => {
    let jsondf = {
      jsoncsv: df.data,
      submissionId: submissionId,
      userEmail: user.email,
      submissionName: submissionDefaults.submissionName,
    };
    let response = await http.post(API.EXPORT_CSV, jsondf, {
      headers: { "Content-Type": "text/csv" },
      responseType: "blob",
    });
    return response;
  };

  const downloadcsv = async () => {
    await exportcsv(df, submissionId).then((response) => {
      let csvblob = response.data;
      let csvurl = window.URL.createObjectURL(csvblob);
      let link = document.createElement("a");
      link.href = csvurl;
      link.setAttribute("download", submissionId + ".csv");
      document.body.appendChild(link);
      link.click();
    }).catch((error) => {
      console.log(error);
      window.alert("Error in downloading csv, contact us if this persists");
    });
  };

  const openInputbox = () => {
    inputFile.current.click();
  };

  const uploadcsv = async () => {
    if (selectedFiles.length > 0) {
      alert("Cannot be undone");
      let currentFile = selectedFiles[0];
      await upload(
        API.IMPORT_CSV,
        currentFile,
        user.email,
        submissionId,
        submissionDefaults,
        totalLength
      ).then((response) => {
        notify("Import Successful");
        setNewDf(response.data.meta.df);
      }).catch((error) => {
        console.log(error);
        window.alert("Error in uploading csv, contact us if this persists");
      }
      );
    }
  };

  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
  };

  useEffect(() => {
    uploadcsv();
  }, [selectedFiles]);

  return (
    <div style={{ marginTop: "5vh" }}>
      {/* <Walkthrough /> */}
      <ReactTooltip className="tooltip" />
      <div style={{ display: "flex", justifyContent: "center" }}>
        {/* <span className="autosave" >Save every<input type="number" placeholder="" name="duration" min="1" onChange={(e)=>{setAutoSave(e.target.value!==0? e.target.value: 1)} } className="autosavedur"/> minutes</span> */}
        <Button
          onClick={() => { toast.dismiss(); }}
          className="submitmusic"
          style={{ marginInline: "5px", marginBlock: "2vh" }}
        >
          Clear All Messages
        </Button>
        {/* <Button
          onClick={openInputbox}
          className="submitmusic"
          style={{ marginInline: "5px", marginBlock: "2vh" }}
          data-tip="Upload a CSV file to replace your current tags"
        >
          Import CSV
        </Button>
        <Button
          onClick={downloadcsv}
          className="submitmusic"
          style={{ marginInline: "5px", marginBlock: "2vh" }}
          data-tip="<p>Download a CSV file with your current tags<br /> to edit them in Excel or Google Sheets<br/><b>DO NOT EDIT THE FILENAME COLUMN AS THIS KEEPS TRACK<br/> OF THE FILES IN YOUR ORIGINAL SUBMISSION</b> </p>"
          data-html={true}
        >
          Export CSV
        </Button> */}
        <Button
          onClick={() => saveprogress("Saved Successfully")}
          type="submit"
          className={
            saved === "saved" ? "submitmusic savebutton" : "submitpink submitmusic savebutton"
          }
          style={{ marginInline: "5px", marginBlock: "2vh" }}
        >
          Save
        </Button>
        <Button
          onClick={() => checkSubmission(df.data)}
          type="submit"
          className="submitpink submitmusic"
        >
          Find Incomplete Rows
        </Button>
        <Button
          onClick={typage}
          type="submit"
          className="submitpink submitmusic"
        >
          Submit
        </Button>
      </div>
      <div>
        <div style={{ borderWidth: "1px" }} className="react-table-container">
          <EditableTable
            grid={df}
            fn={updatedf}
            totalLength={totalLength}
            taskId={taskId}
            submissionId={submissionId}
            submissionDefaults={submissionDefaults}
            email={user.email}
            saved={saved}
            setTotalLength={setTotalLength}
            newDf={newDf}
          />
          <div>
            <input
              id="importcsv"
              type="file"
              ref={inputFile}
              style={{ display: "none" }}
              onChange={selectFile}
              accept=".csv, text/csv"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CollectedInfo;
