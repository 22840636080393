// import ReactTagInput from "@pathofdev/react-tag-input";
// import "@pathofd ev/react-tag-input/build/index.css";
// import ReactTagInput from "./Components/ReactTagsDemo";
// import React, { useState, useEffect } from "react";
// import { WithContext as ReactTags } from "react-tag-input";
// import CategorizedTagInput from "./Components/cattag/ClassCategorizedTagInput";

import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { createPortal } from "react-dom";
import { ActionTypes, DataTypes, randomColor, maxTagLength } from "../utils";
import { grey } from "./colors";
import _ from "lodash";
import ContentEditable from "react-contenteditable";
import Badge from "./Badge";
import PlusIcon from "../img/Plus";
import Input from "../Components/cattag/ClassInput";
import Panel from "../Components/cattag/Panel";
import * as key from '../Components/cattag/keyboard';

import allcategories from "./allcategories.json"
import "../style.css";
import "../ReactTags.css";
import "../Components/cattag/categorized-tag-input.css";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export function isCategoryItemValid(i) {
  return typeof i === 'string' && i.trim().length > 0;
}

export function isCategoryValid(c) {
  return typeof c === 'object'
    && c.id
    && c.title
    && c.items
    && Array.isArray(c.items)
    && c.items.every(isCategoryItemValid)
    && (c.type || c.single);
}

export default function Cell({
  value: initialValue,
  row,
  column: { id, dataType, options },
  dataDispatch,
}) {

  const index = row.index;
  const categories = allcategories.filter(c => c.id === id);
  const [value, setValue] = useState({ value: initialValue, update: false });
  const [selectRef, setSelectRef] = useState(null);
  const [addSelectRef, setAddSelectRef] = useState(null);
  const [selectPop, setSelectPop] = useState(null);
  const [showSelect, setShowSelect] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const { styles, attributes } = usePopper(selectRef, selectPop, {
    placement: "bottom-start",
    strategy: "fixed",
  });

  const [catTags, setcatTags] = useState();
  const [inputState, setInputState] = useState("");
  const [catState, setCatState] = useState(
    {
      value: '',
      selection: {
        item: 0,
        category: 0
      },
      categories: categories,
      addNew: false
    }
  );

  const [tags, setTags] = useState();
  const [suggestions, setSuggestions] = useState();
  const [tagChange, setTagChange] = useState(false);

  function handleOptionKeyDown(e) {
    if (e.key === "Enter") {
      if (e.target.value !== "") {
        dataDispatch({
          type: ActionTypes.ADD_OPTION_TO_COLUMN,
          option: e.target.value,
          backgroundColor: randomColor(),
          columnId: id,
        });
      }
      setShowAdd(false);
    }
  }

  function handleAddOption(e) {
    setShowAdd(true);
  }

  function handleOptionBlur(e) {
    if (e.target.value !== "") {
      dataDispatch({
        type: ActionTypes.ADD_OPTION_TO_COLUMN,
        option: e.target.value,
        backgroundColor: randomColor(),
        columnId: id,
      });
    }
    setShowAdd(false);
  }

  function getColor() {
    let match = options.find((option) => option.label === value.value);
    return (match && match.backgroundColor) || grey(200);
  }

  function onChange(e) {
    setValue({ value: e.target.value, update: false });
  }

  function handleOptionClick(option) {
    setValue({ value: option.label, update: true });
    setShowSelect(false);
  }

  function handleIds(id) {
    if (id === "frequency range" || id === "mood intensity(1 - lowest, 5 - highest)" || id === "audio type") {
      return false;
    }
    return true;
  }

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
    setTagChange(true);
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
    if (_.findIndex(suggestions, tag) === -1) {
      setSuggestions([...suggestions, tag]);
    }

    setTagChange(true);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = [...tags].slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    setTags(newTags);
  };

  // CATEGORIZED TAG INPUT REFACTOR

  const filterCategories = (input) => {
    let newCategories = categories.map(c => {
      c = Object.assign({}, c, {
        items: c.items.filter(filterItems(input))
      });
      return (c.items.length === 0 && (!catState.addNew || c.single)) ? null : c;
    }).filter(c => c !== null);

    let selection = catState.selection;
    if (catState.selection.category >= newCategories.length) {
      selection = {
        category: 0,
        item: 0
      };
    } else {
      if (selection.item >= newCategories[selection.category].items.length) {
        selection.item = 0;
      }
    }

    setCatState({
      ...catState,
      value: input,
      categories: newCategories,
      selection: selection
    });
  }

  const filterItems = (input) => {
    return function (i) {
      if (input.length === 1) {
        return i.toLowerCase().trim() === input;
      }
      return i.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
    };
  }

  const onInputValueChange = (e) => {
    let input = e.target.value;
    setCatState({
      ...catState,
      value: input
    });
    filterCategories(input);
    setInputState(input);
  }

  const onTagDeleted = (i) => {
    const newTags = catTags.slice()
    newTags.splice(i, 1)
    setcatTags(newTags);
    setTagChange(true);
  }

  const onAdd = (newTag) => {
    setShowSelect(false);
    setcatTags(catTags.length < maxTagLength(id) ? catTags.concat([newTag]) : catTags);
    setCatState({
      ...catState,
      value: '',
      categories: categories,
    });
    setInputState('');
    setTagChange(true);

    // input.current.focusInput();
  }

  const addSelectedTag = () => {
    const ncategory = catState.categories[catState.selection.category];
    const ntitle = ncategory.items[catState.selection.item];
    onAdd({
      category: ncategory.id,
      title: ntitle || catState.value
    });
    setShowSelect(false);

  }

  const handleBackspace = (e) => {
    if (catState.value.trim().length === 0) {
      e.preventDefault();
      onTagDeleted(catTags.length - 1);
    }
  }

  const handleArrowLeft = () => {
    let result = catState.selection.item - 1;
    let cat = catState.categories[catState.selection.category];
    setCatState({
      ...catState,
      selection: {
        category: catState.selection.category,
        item: result < 0 ? Math.max(0, cat.items.length - 1) : result
      }
    });
  }

  // const handleArrowUp = () => {
  //   let result = catState.selection.category - 1;
  //   setCatState({
  //     ...catState,
  //     selection: {
  //       category: result >= 0 ? result : 0,
  //       item: 0
  //     }
  //   });
  // }

  const handleArrowRight = () => {
    let result = catState.selection.item + 1;
    let cat = catState.categories[catState.selection.category];
    setCatState({
      ...catState,
      selection: {
        category: catState.selection.category,
        item: result < cat.items.length ? result : result % cat.items.length
      }
    });
  }

  // const handleArrowDown = () => {
  //   let result = catState.selection.category + 1;
  //   let cats = catState.categories;
  //   setCatState({
  //     ...catState,
  //     selection: {
  //       category: result < cats.length ? result : cats.length - 1,
  //       item: 0
  //     }
  //   });
  // }

  const onCatKeyDown = (e) => {
    let result;
    switch (e.keyCode) {
      case key.TAB:
      case key.ENTER:
        setCatState({
          ...catState,
          value: ''
        });
        setInputState('');
        if (!catState.value) {

          // enable normal tab/enter behavior
          // (don't preventDefault)
          break;
        }
      case key.COMMA:
        e.preventDefault();
        addSelectedTag();
        break;
      case key.BACKSPACE:
        handleBackspace(e);
        break;
      case key.UP:
        handleArrowLeft();
        break;
      case key.LEFT:
        handleArrowLeft();
        break;
      case key.DOWN:
        handleArrowRight();
        break;
      case key.RIGHT:
        handleArrowRight();
        break;
    }
  }

  function getCellElement() {
    switch (dataType) {
      case DataTypes.STATIC:
        return (
          <div
            className="data-input cell-padding d-flex cursor-default align-items-center flex-1"
            style={{ minHeight: "60px" }}
            onClick={() => { document.querySelectorAll('audio').forEach(vid => vid.pause()) }}
          >
            {(value.value && value.value.toString()) || ""}
          </div>
        );
      case DataTypes.STATIC_NUMBER:
        return (
          <div
            className="data-input text-align-right cell-padding d-flex cursor-default align-items-center flex-1"
            style={{ minHeight: "60px" }}
          >
            {(value.value && value.value.toString()) || ""}
          </div>
        );
      case DataTypes.TEXT:
        return (
          <ContentEditable
            html={(value.value && value.value.toString()) || ""}
            onChange={onChange}
            onBlur={() =>
              setValue((old) => ({ value: old.value, update: true }))
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (e.target.value !== "") {
                  setValue((old) => ({ value: old.value, update: true }));
                }
              }
            }}
            className="data-input cell-padding d-flex cursor-default align-items-center flex-1"
            style={{ minHeight: "60px" }}
          />
        );
      case DataTypes.NUMBER:
        return (
          <ContentEditable
            html={(value.value && value.value.toString()) || ""}
            onChange={onChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (e.target.value !== "") {
                  setValue((old) => ({ value: old.value, update: true }));
                }
              }
            }}
            onBlur={() =>
              setValue((old) => ({ value: old.value, update: true }))
            }
            className="data-input text-align-right cell-padding d-flex cursor-default align-items-center flex-1"
            style={{ minHeight: "60px" }}
          />
        );

      case DataTypes.MULTI_SELECT:
        if (!(catTags)) {
          if (_.isArray(value.value)) {
            setcatTags(
              _.isEmpty([value.value])
                ? []
                : value.value.map((value1) => {
                  return {
                    category: id,
                    title: value1,
                  };
                })
            );
          } else {
            let temp = [];
            if (typeof value.value === "string" && value.value !== "" && value.value !== null) {
              temp = value.value.split(",");
              setcatTags(temp.map((value1) => { return { category: id, title: value1 }; }));
            } else {
              setcatTags([]);
            }
          }
        }
        return (
          <div onBlur={() => { setValue((old) => ({ value: old.value, update: false })) }}>
            {/* show tag display */}

            <div
              ref={setSelectRef}
              className="select cell-padding d-flex cursor-default align-items-center flex-1"
              onClick={() => {
                if (catTags.length < 3) {
                  setShowSelect(true);
                }
              }}
              style={{ minHeight: "60px" }}
            >
              {/* 
              {value.value && (
                <Badge value={value.value} backgroundColor={getColor()} />
              )} */}

              <Input
                openPanel={() => {
                  if (catTags.length < maxTagLength(id)) {
                    setShowSelect(true);
                  }
                }}
                closePanel={() => setShowSelect(false)}
                onValueChange={onInputValueChange}
                onTagDeleted={onTagDeleted}
                onKeyDown={onCatKeyDown}
                placeholder={"Type to filter, press enter or tab to autocomplete"}
                value={inputState.value}
                getTagStyle={() => { }}
                tags={catTags}
                maxTagLength={maxTagLength(id)}
                transformTag={
                  (tag) => {
                    // const categoryMatches = categories.filter(category => category.id === tag.category);
                    // const categoryTitle = categoryMatches[0].title;
                    return `${tag.title}`;
                  }
                }
              />
            </div>

            {/* hide on click */}
            {showSelect && (
              <div className="overlay" onClick={() => setShowSelect(false)} />
            )}

            {/* show popup */}
            {showSelect &&
              createPortal(
                <div
                  className="shadow-5 bg-white border-radius-md"
                  ref={setSelectPop}
                  {...attributes.popper}
                  style={{
                    ...styles.popper,
                    zIndex: 4,
                    maxWidth: 500,
                    maxHeight: 400,
                    padding: "0.5rem",
                    overflow: "auto",
                  }}
                >
                  {/* SHOW TAG BADGES */}
                  {
                    <div className="d-flex flex-wrap-wrap" style={{ marginTop: "0rem" }} >
                      <Panel
                        categories={catState.categories}
                        selection={catState.selection}
                        input={catState.value}
                        onAdd={onAdd}
                        getTagStyle={() => { }}
                        getCreateNewText={() => { }}
                        addNew={false}
                        id={id}
                      />
                    </div>
                  }
                  {/* SHOW ADD BUTTON AND INPUT */}
                </div>,
                document.querySelector("#popper-portal")
              )}
          </div>
        );

      case DataTypes.SELECT:
        return (
          <>
            <div
              ref={setSelectRef}
              className="select cell-padding d-flex cursor-default align-items-center flex-1"
              onClick={() => setShowSelect(true)}
              style={{ minHeight: "60px" }}
            >
              {value.value && (
                <Badge value={value.value} backgroundColor={getColor()} />
              )}
            </div>
            {showSelect && (
              <div className="overlay" onClick={() => setShowSelect(false)} />
            )}
            {showSelect &&
              createPortal(
                <div
                  className="shadow-5 bg-white border-radius-md"
                  ref={setSelectPop}
                  {...attributes.popper}
                  style={{
                    ...styles.popper,
                    zIndex: 4,
                    maxWidth: 320,
                    maxHeight: 400,
                    padding: "0.5rem",
                    overflow: "auto",
                  }}
                >
                  {/* SHOW TAG BADGES */}

                  <div className="d-flex flex-wrap-wrap" style={{ marginTop: "0rem" }} >
                    {options.map((option) => (
                      <div
                        className="cursor-pointer mr-5"
                        style={{ marginBlock: "0.5rem" }}
                        onClick={() => handleOptionClick(option)}
                      >
                        <Badge
                          value={option.label}
                          backgroundColor={option.backgroundColor}
                        />
                      </div>
                    ))}
                  </div>

                  {/* SHOW ADD BUTTON AND INPUT */}

                  <div style={{ fontFamily: "Poppins" }}>
                    {showAdd && (
                      <div
                        className="bg-grey-200 border-radius-sm"
                        style={{
                          width: 120,
                          padding: "2px 4px",
                          marginTop: "10px",
                        }}
                      >
                        <input
                          type="text"
                          className="option-input"
                          onBlur={handleOptionBlur}
                          ref={setAddSelectRef}
                          onKeyDown={handleOptionKeyDown}
                        />
                      </div>
                    )}
                    <div
                      className="cursor-pointer mr-5"
                      style={{ justifyContent: "center" }}
                      onClick={handleAddOption}
                    >
                      {handleIds(id) && (
                        <Badge
                          value={
                            <span className="svg-icon-sm svg-text">
                              <PlusIcon />
                            </span>
                          }
                          backgroundColor={grey(200)}
                        />
                      )}
                    </div>
                  </div>

                </div>,
                document.querySelector("#popper-portal")
              )}
          </>
        );
      default:
        return <span></span>;
    }
  }


  useEffect(() => {
    if (addSelectRef && showAdd) {
      addSelectRef.focus();
    }
  }, [addSelectRef, showAdd]);

  useEffect(() => {
    setValue({ value: initialValue, update: false });
  }, [initialValue]);

  useEffect(() => {
    if (value.update) {
      dataDispatch({
        type: ActionTypes.UPDATE_CELL,
        columnId: id,
        rowIndex: index,
        value: value.value,
        filename: row.original.filename,
        row: row.original,
      });
    }
  }, [value, dataDispatch, id, index, row.original.filename, row.original]);

  // useEffect(() => {
  // if (tagChange) {
  //   setValue({
  //     value: tags === [] ? "" : tags.map((tag) => tag.text).toString(),
  //     update: false,
  //   });
  //   setTagChange(false);
  //   }
  // }, [tags]);

  useEffect(() => {
    if (tagChange) {
      let newCatTags = catTags;
      // console.log("newCatTags", catTags);
      // remove duplicates from newCatTags where newCatTags[i].title === newCatTags[j].title

      for (let i = 0; i < newCatTags.length; i++) {
        for (let j = i + 1; j < newCatTags.length; j++) {
          if (newCatTags[i].title === newCatTags[j].title) {
            newCatTags.splice(j, 1);
          }
        }
      }
      // console.log("newCatTags", newCatTags);

      // newCatTags = newCatTags.filter((tag, index) => { return newCatTags.indexOf(tag) === index; });
      // console.log("newCatTags", newCatTags);
      setValue({
        value: newCatTags === [] ? "" : newCatTags.map((tag) => tag.title).toString(),
        update: true,
      });
      setTagChange(false);
    }
  }, [catTags]);


  // const onTagUpdate = (i, newTag) => {
  //   const updatedTags = tags.slice();
  //   updatedTags.splice(i, 1, newTag);
  //   setTags(updatedTags);
  // };

  // useEffect(() => {
  //   return tags
  //     ? setTagState(
  //         tags.map((value) => {
  //           return value.text;
  //         })
  //       )
  //     : [];
  // }, [tags]);


  return getCellElement();
}