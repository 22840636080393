import React, { useMemo, useState, useEffect, useRef } from "react";
import clsx from "clsx";
import {
    useTable,
    useBlockLayout,
    useResizeColumns,
    useSortBy,
    usePagination,
} from "react-table";
import Cell from "./Cell";
import Header from "./Header";
import PlusIcon from "../img/Plus";
import {
    API,
    upload,
    ActionTypes,
    rowIsEmptyAndMandatory,
} from "../utils";
import "../App.css";
import "../style.css";
import { useSticky } from "react-table-sticky";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DarkModeToggle from "react-dark-mode-toggle";

const defaultColumn = {
    minWidth: 50,
    width: 150,
    maxWidth: 400,
    Cell: Cell,
    Header: Header,
    sortType: "alphanumericFalsyLast",
};

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <label
                className="hidecol"
                style={{
                    height: "fit-content",
                    marginInline: "2px",
                    padding: "4px",
                    alignItems: "center",
                }}
            >
                <input type="checkbox" ref={resolvedRef} {...rest} />
                Toggle All
            </label>
        );
    }
);

export default function Table2({
    columns,
    data,
    dispatch: dataDispatch,
    skipReset,
    totalLength,
    setTotalLength,
    submissionId,
    submissionDefaults,
    email,
    saved,
}) {
    const [rowres, setRowres] = useState();
    // const [time, setTime] = useState(tl);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [pageIndexState, setPageIndexState] = useState(0);
    const [viewMode, setViewMode] = useState(() => true);
    const [darkLight, setDarkLight] = useState("td-dark");

    const inputFile = useRef();

    const sortTypes = useMemo(
        () => ({
            alphanumericFalsyLast(rowA, rowB, columnId, desc) {
                if (!rowA.values[columnId] && !rowB.values[columnId]) {
                    return 0;
                }

                if (!rowA.values[columnId]) {
                    return desc ? -1 : 1;
                }

                if (!rowB.values[columnId]) {
                    return desc ? 1 : -1;
                }

                return isNaN(rowA.values[columnId])
                    ? rowA.values[columnId].localeCompare(rowB.values[columnId])
                    : rowA.values[columnId] - rowB.values[columnId];
            },
        }),
        []
    );

    const {
        getTableProps,
        headerGroups,
        getToggleHideAllColumnsProps,
        allColumns,
        prepareRow,
        rows,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            dataDispatch,
            autoResetSortBy: !skipReset,
            autoResetFilters: !skipReset,
            autoResetRowState: !skipReset,
            sortTypes,
            initialState: { pageIndex: pageIndexState },
        },
        useBlockLayout,
        useResizeColumns,
        useSortBy,
        usePagination,
        useSticky
    );

    const sty = (index) => {
        return {
            display: "flex",
            minHeight: "60",
            left: 0,
            right: undefined,
            top: index * 60,
            // width: "100%",
        };
    };

    const rowIsValid = (row) => {
        let rowValid = true;
        for (const feild in row) {
            // if row is empty and feild is mandatory
            if (rowIsEmptyAndMandatory(row, feild)) {
                rowValid = false;
            }
        }
        return rowValid;
    };

    const RenderRow = React.useCallback(
        ({ index, style }) => {
            const row = rows[index];
            prepareRow(row);

            let rowValid = rowIsValid(row.original);

            return (
                <div {...row.getRowProps({ style })} className="tr">
                    {row.cells.map((cell) => {
                        return (
                            <div
                                {...cell.getCellProps()}
                                className={
                                    rowValid && cell.column.id === "no"
                                        ? "tdincomplete"
                                        : darkLight
                                }
                            >
                                {cell.render("Cell")}
                            </div>
                        );
                    })}
                </div>
            );
        },
        [prepareRow, rows]
    );

    useEffect(() => {
        gotoPage(pageIndexState);
    }, [pageIndexState]);

    const selectFile = (event) => {
        setSelectedFiles(event.target.files);
    };

    // notificaton for successful upload
    const notify = (name) => toast("✔ " + name + " Added To Last Page");

    const addNewFile = async (tl) => {
        if (selectedFiles.length > 0) {
            let currentFile = selectedFiles[0];
            upload(
                API.APPEND,
                currentFile,
                email,
                submissionId,
                submissionDefaults,
                tl
            )
                .then((response) => {
                    let rowarr = JSON.parse(response.data.meta.df).data;
                    setRowres(rowarr);
                    notify(JSON.parse(response.data.meta.df).data[0].filename);
                    setTotalLength(response.data.meta.tl);
                })
                .catch((error) => {
                    console.log(error);
                    window.alert(
                        `Error uploading file: ${error.response.data.reason}`
                    );
                });
        }
    };

    useEffect(() => {
        addNewFile(totalLength);
    }, [selectedFiles]);

    useEffect(() => {
        if (rowres !== undefined) {
            dataDispatch({ type: ActionTypes.ADD_ROWNEW, rowd: rowres });
        }
    }, [rowres]);

    useEffect(() => {
        if (viewMode) {
            setDarkLight("td-dark");
        } else {
            setDarkLight("td");
        }
        dataDispatch({ type: ActionTypes.ENABLE_RESET });
    }, [viewMode]);

    const openInputbox = () => {
        inputFile.current.click();
    };

    function isTableResizing() {
        for (let headerGroup of headerGroups) {
            for (let column of headerGroup.headers) {
                if (column.isResizing) {
                    return true;
                }
            }
        }

        return false;
    }

    return (
        <>
            {/* Page control panel */}
            <div className="pagen">
                <DarkModeToggle
                    onChange={setViewMode}
                    checked={viewMode}
                    size={75}
                />
                <div
                    className="hid"
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginInline: "1vw",
                        fontSize: "14px",
                    }}
                >
                    <IndeterminateCheckbox
                        {...getToggleHideAllColumnsProps()}
                    />{" "}
                </div>
                {/* Pagination buttons */}
                <button
                    onClick={() => setPageIndexState(0)}
                    disabled={!canPreviousPage}
                    className="pageinationbtn"
                    style={{
                        borderBottomLeftRadius: "5px",
                        borderTopLeftRadius: "5px",
                        borderBottomRightRadius: "0px",
                    }}
                >
                    {"<<"}
                </button>
                <button
                    onClick={() => {
                        setPageIndexState(pageIndexState - 1);
                    }}
                    disabled={!canPreviousPage}
                    className="pageinationbtn"
                >
                    {"<"}
                </button>
                <button
                    onClick={() => {
                        setPageIndexState(pageIndexState + 1);
                    }}
                    disabled={!canNextPage}
                    className="pageinationbtn"
                >
                    {">"}
                </button>
                <button
                    onClick={() => setPageIndexState(pageCount - 1)}
                    disabled={!canNextPage}
                    className="pageinationbtn"
                    style={{
                        borderTopRightRadius: "5px",
                        borderBottomRightRadius: "5px",
                        marginInlineEnd: "1vw",
                    }}
                >
                    {">>"}
                </button>
                <span>
                    Page{" "}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{" "}
                    | Go to page:{" "}
                    <input
                        type="number"
                        defaultValue={pageIndexState + 1}
                        onChange={(e) => {
                            setPageIndexState(e.target.value - 1);
                        }}
                        style={{ width: "100px" }}
                        value={pageIndexState + 1}
                    />
                </span>{" "}
                <select
                    value={pageSize}
                    onChange={(e) => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[10, 20, 30].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
                {/* submission metadata */}
                <div style={{ marginLeft: "10px" }}>
                    {rows.length} samples, {totalLength.toFixed(2)} seconds
                    total.
                </div>
                {/* save status */}
                <div style={{ marginLeft: "10px" }}>{saved}</div>
            </div>
            {/* end of page control panel */}

            {/* main table */}
            <div className="overflow-y-auto">
                <div
                    {...getTableProps()}
                    className={
                        "new" || clsx("table", isTableResizing() && "noselect")
                    }
                >
                    <div>
                        {/* HEADER ROW */}
                        <div>
                            {/* HIDE COLUMN CHECKBOXES */}
                            <div
                                className="hid"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    marginInlineStart: "-2px",
                                    fontSize: "14px",
                                }}
                            >
                                {allColumns.map((column) => (
                                    <label
                                        className="hidecol"
                                        style={{
                                            width: column.totalWidth - 4,
                                            marginInline: "2px",
                                        }}
                                    >
                                        <input
                                            type="checkbox"
                                            id={column.id}
                                            {...column.getToggleHiddenProps()}
                                        />

                                        {column.label}
                                    </label>
                                ))}
                            </div>
                            {/* END OF HIDE COLUMN CHECKBOXES */}
                            {/* HEADERS */}
                            {headerGroups.map((headerGroup) => (
                                <div
                                    {...headerGroup.getHeaderGroupProps({
                                        border: "none",
                                    })}
                                    className="hdr"
                                >
                                    {headerGroup.headers.map((column) =>
                                        column.render("Header")
                                    )}
                                </div>
                            ))}
                        </div>
                        {/* END HEADERS */}
                        {/* END HEADER ROW */}

                        {/* ROWS */}
                        {page.map((i) => (
                            <RenderRow index={i.index} style={sty(i.index)} />
                        ))}
                        {/* END ROWS */}

                        {/* FOOTER ROW */}
                        <div
                            className="tr add-row"
                            data-tip="<div>add new sample</div>"
                            data-html={true}
                            onClick={openInputbox}
                        >
                            <div className="title">
                                <span className="svg-icon svg-gray icon-margin">
                                    <PlusIcon />
                                </span>
                                Add new sample
                            </div>
                            <input
                                id="newrow"
                                type="file"
                                ref={inputFile}
                                style={{ display: "none" }}
                                onChange={selectFile}
                                accept="audio/wav, audio/midi"
                            />
                        </div>
                        {/* END FOOTER ROW */}
                        <ToastContainer />
                    </div>
                </div>
            </div>
            {/* END TABLE */}
        </>
    );
}
