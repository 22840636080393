import React, { useState, useEffect } from "react";
import Cell from "./Cell";
import Header from "./Header";
import clsx from "clsx";
import {
  useTable,
  useBlockLayout,
  useResizeColumns,
  useSortBy,
  usePagination,
} from "react-table";
import "../App.css";
import "../style.css";

const defaultColumn = {
  minWidth: 30,
  width: 50,
  maxWidth: 400,
  Cell: Cell,
  Header: Header,
  sortType: "alphanumericFalsyLast",
};

function ProfileTable({ columns, data, dispatch: dataDispatch }) {

  const [pageIndexState, setPageIndexState] = useState(0);

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      dataDispatch,
      initialState: { pageIndex: pageIndexState },
    },
    useBlockLayout,
    useResizeColumns,
    useSortBy,
    usePagination
  );

  const sty = (index) => {
    return {
      display: "flex",
      minHeight: "60",
      left: 0,
      right: undefined,
      top: index * 60,
      // width: "100%",
    };
  };

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div {...row.getRowProps({ style })} className="tr">
          {row.cells.map((cell) => {
            return (
              <div {...cell.getCellProps()} className="td">
                {cell.render("Cell")}
              </div>
            );
          })}
        </div>
      );
    },
    [prepareRow, rows]
  );

  useEffect(() => {
    gotoPage(pageIndexState);
  }, [pageIndexState]);

  function isTableResizing() {
    for (let headerGroup of headerGroups) {
      for (let column of headerGroup.headers) {
        if (column.isResizing) {
          return true;
        }
      }
    }

    return false;
  }
  return (
    <>
      <div style={{ borderWidth: "1px" }} className="react-table-container">
        <div style={{marginTop:"5vh"}}>
          <div className="pagen">
          <button
          onClick={() => setPageIndexState(0)}
          disabled={!canPreviousPage}
          className="pageinationbtn"
          style={{borderBottomLeftRadius:"5px",borderTopLeftRadius:"5px",borderBottomRightRadius:"0px"}}
        >
          {"<<"}
        </button>
        <button
          onClick={() => {
            setPageIndexState(pageIndexState - 1);
          }}
          disabled={!canPreviousPage}
          className="pageinationbtn"
        >
          {"<"}
        </button>
        <button
          onClick={() => {
            setPageIndexState(pageIndexState + 1);
          }}
          disabled={!canNextPage}
          className="pageinationbtn"
        >
          {">"}
        </button>
        <button
          onClick={() => setPageIndexState(pageCount - 1)}
          disabled={!canNextPage}
          className="pageinationbtn"
          style={{borderTopRightRadius:"5px",borderBottomRightRadius:"5px", marginInlineEnd:"1vw"}}
        >
          {">>"}
        </button>
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
              | Go to page:{" "}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "100px" }}
              />
            </span>{" "}
            <select
              style={{ marginInline: "1vw" }}
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>

          <div className="profilepg" style={{}}>
            <div
              {...getTableProps()}
              className={"new" || clsx("table", isTableResizing() && "noselect")}

              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0px",
                padding: "0px",
                margin: "0px",
              }}
            >
              <div>
                {headerGroups.map((headerGroup) => (
                  <div
                    {...headerGroup.getHeaderGroupProps({ border: "none" })}
                    className="hdr"
                  >
                    {headerGroup.headers.map((column) =>
                      column.render("Header")
                    )}
                  </div>
                ))}
              </div>
              {page.map((i) => (
                <RenderRow index={i.index} style={sty(i.index)} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ProfileTable;
