import React, { useState } from "react";

const Collapsible = ({ title, children, initOpen = false }) => {
    const [isOpen, setIsOpen] = useState(initOpen);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="instructions">
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    cursor: "pointer",
                }}
                onClick={toggleOpen}
            >
                <h1
                    style={{
                        marginRight: "1rem",
                    }}
                >
                    {title}
                </h1>
                <img
                    src="https://img.icons8.com/ios/452/expand-arrow.png"
                    // invert the image color
                    style={{
                        filter: "invert(1)",
                        cursor: "pointer",
                        transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                        // marginLeft: 'auto'
                    }}
                    alt="expand"
                    width="40"
                />
            </div>
            {isOpen && <div className="collapsible-content">{children}</div>}
        </div>
    );
};

export default Collapsible;
