import React, { useState, useEffect, useReducer } from "react";
import update from "immutability-helper";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "../App.css";
import "../style.css";
import { ActionTypes, DataTypes, selectMaker, randomColor } from "../utils";
import Table from "../TableUtils/Table";
import ReactTooltip from 'react-tooltip';
import Collapsible from "./Collapsible";

function reducer(state, action) {
  switch (action.type) {
    case ActionTypes.ADD_OPTION_TO_COLUMN:
      const optionIndex = state.columns.findIndex(
        (column) => column.id === action.columnId
      );
      return update(state, {
        skipReset: { $set: true },
        columns: {
          [optionIndex]: {
            options: {
              $push: [
                {
                  label: action.option,
                  backgroundColor: action.backgroundColor,
                },
              ],
            },
          },
        },
      });
    case ActionTypes.ADD_ROW:
      return update(state, {
        skipReset: { $set: true },
        data: { $push: [{}] },
      });
    case ActionTypes.ADD_ROWNEW:
      return update(state, {
        skipReset: { $set: true },
        data: { $push: action.rowd },
      });
    case ActionTypes.UPDATE_COLUMN_TYPE:
      const typeIndex = state.columns.findIndex(
        (column) => column.id === action.columnId
      );
      switch (action.dataType) {
        case DataTypes.NUMBER:
          if (state.columns[typeIndex].dataType === DataTypes.NUMBER) {
            return state;
          } else {
            return update(state, {
              skipReset: { $set: true },
              columns: { [typeIndex]: { dataType: { $set: action.dataType } } },
              data: {
                $apply: (data) =>
                  data.map((row) => ({
                    ...row,
                    [action.columnId]: isNaN(row[action.columnId])
                      ? ""
                      : Number.parseInt(row[action.columnId]),
                  })),
              },
            });
          }
        case DataTypes.SELECT:
          if (state.columns[typeIndex].dataType === DataTypes.SELECT) {
            return state;
          } else if (
            state.columns[typeIndex].dataType === DataTypes.MULTI_SELECT
          ) {
            return update(state, {
              skipReset: { $set: true },
              columns: {
                [typeIndex]: {
                  dataType: { $set: action.dataType },
                  options: {
                    $apply: (options) => {
                      let temp = options;
                      return temp.map((lab) => {
                        return {
                          label: lab,
                          backgroundColor: randomColor(),
                        };
                      });
                    },
                  },
                },
              },
            });
          } else {
            let options = selectMaker(state.data, action.columnId);

            return update(state, {
              skipReset: { $set: true },
              columns: {
                [typeIndex]: {
                  dataType: { $set: action.dataType },
                  options: { $push: options },
                },
              },
            });
          }
        case DataTypes.MULTI_SELECT:
          if (
            state.columns[typeIndex].dataType === DataTypes.MULTI_SELECT ||
            action.columnId === "time signature" ||
            action.columnId === "tempo" ||
            action.columnId === "length(number of bars)"
          ) {
            return state;
          } else {
            return update(state, {
              skipReset: { $set: true },
              columns: {
                [typeIndex]: {
                  dataType: { $set: action.dataType },
                  options: {
                    $apply: (options) => {
                      let temp = options.map((option) => {
                        if (option.label !== "null") {
                          return option.label;
                        }
                      });
                      return temp.toString();
                    },
                  },
                },
              },
            });
          }
        case DataTypes.TEXT:
          if (state.columns[typeIndex].dataType === DataTypes.TEXT) {
            return state;
          } else if (state.columns[typeIndex].dataType === DataTypes.SELECT) {
            return update(state, {
              skipReset: { $set: true },
              columns: { [typeIndex]: { dataType: { $set: action.dataType } } },
            });
          } else if (
            state.columns[typeIndex].dataType === DataTypes.MULTI_SELECT
          ) {
            return update(state, {
              skipReset: { $set: true },
              columns: { [typeIndex]: { dataType: { $set: action.dataType } } },
              data: {
                $apply: (data) =>
                  data.map((row) => ({
                    ...row,
                    [action.columnId]: !(
                      typeof row[action.columnId] === "string" &&
                      row[action.columnId].length > 0
                    )
                      ? row[action.columnId]
                      : "row[action.columnId]",
                  })),
              },
            });
          } else {
            return update(state, {
              skipReset: { $set: true },
              columns: { [typeIndex]: { dataType: { $set: action.dataType } } },
              data: {
                $apply: (data) =>
                  data.map((row) => ({
                    ...row,
                    [action.columnId]: row[action.columnId] + "",
                  })),
              },
            });
          }
        default:
          return state;
      }
    case ActionTypes.UPDATE_CELL:
      return update(state, {
        skipReset: { $set: true },
        data: {
          [action.rowIndex]: { [action.columnId]: { $set: action.value } },
        },
      });
    case ActionTypes.UPDATE_ROW:
      return update(state, {
        skipReset: { $set: true },
        data: {
          [action.index]: { $set: action.payload },
        },
      });
    default:
      return state;
  }
}

const Instructions = () => {
  let history = useHistory();
  function buttonredirect() {
    history.push("/submitmusic");
  }
  const columns = [
    {
      id: "no",
      label: " #",
      dataType: DataTypes.STATIC_NUMBER,
      width: 10,
      options: [],
      sticky: "left",
      datatip: "Row number",
      Cell: (row) => {
        return (
          <>
            <div
              className="srno cell-padding d-flex cursor-default align-items-center flex-1"
              style={{ minHeight: "60px", justifyContent: "center" }}
            >
              <div
                className="font-weight-400 d-inline-block border-radius-sm text-transform-capitalize"
                style={{
                  fontSize: "18px",
                  padding: "5px 5px",
                  fontFamily: "inherit",
                  whiteSpace: "break-spaces",
                  // color: rowValid ? "red" : "black",
                }}
              >
                {row.row.index + 1}
              </div>
            </div>
          </>
        );
      },
    },
    {
      id: "filename",
      label: "File Name*",
      accessor: "filename",
      minWidth: 300,
      dataType: DataTypes.STATIC,
      sticky: "left",
      options: [],
      datatip: "Filename of your wav file",
    },
    {
      id: "audio type",
      label: "Audio Type*",
      accessor: "audio type",
      minWidth: 100,
      dataType: DataTypes.SELECT,
      options: selectMaker(
        ["loops", "one shot", "sfx"].map((tag) => {
          return { "audio type": tag };
        }),
        "audio type"
      ),
      datatip: `<div style="width: 20em">
        <ul>
          <li>loops: audio that loops</li>
          <li>one shot: one shot chords or other non-looping samples</li>
          <li>sfx: sfx like risers or impacts</li>
        </ul>
      </div>`,
    },
    {
      id: "lflag",
      label: "Needs Trimming",
      accessor: "lflag",
      minWidth: 130,
      dataType: DataTypes.STATIC,
      datatip: `<div style="width: 20em">
      <ul>
        <li>This column shows if your audio file loops perfectly or if it might need trimming.</li>
        <li>If the cell says <span style="background-color: black; font-weight: bold">Needs trim, Did you mean XYZ BPM for A bars?</span>, it means that the tempo you mentioned might not be right for a whole number of bars (1 or 2 or 4 or 8 bars for example).</li>
        <li>It also shows the calculation that was done to figure out the number of bars, given the length in seconds of the audio file and the tempo indicated in the filename.</li>
        <li>No. of beats = (BPM * length)/60s</li>
        <li>No. of bars = (No. of beats)/(4 beats per bar)</li>
      </ul>
    </div>`,
    },
    {
      id: "instrument role",
      label: "Instrument Role*",
      accessor: "instrument role",
      minWidth: 250,
      dataType: DataTypes.MULTI_SELECT,
      datatip: `<div style="width: 20em">
      What general role would this loop play in a track?
      <ul>
        <li>Bass: Synth Basslines, 808s, Bass guitars etc</li>
        <li>Chords: Chord loops, Pad loops, something that would provide a foundation for a track</li>
        <li>Melody: A lead synth melody, a piano melody, etc </li>
        <li>Percussion: full drum loops, top loops, other percussion loops like tabla etc</li>
        <li>SFX: Transitions, Fills, or other SFX like risers and impacts</li>
      </ul>
    </div>`,
      options: [],
    },
    {
      id: "instrument",
      label: "Instrument*",
      accessor: "instrument",
      minWidth: 100,
      dataType: DataTypes.MULTI_SELECT,
      datatip: `<div style="width: 20em">
      What instruments were used in the loop?<br />If you cannot find the exact instrument please select the instrument that sounds closest to the one you used.<br />For example, if you used tabla, select percussion. If you used a saxophone, select brass, and so on.
      </div>`,
      options: [],
    },
    {
      id: "mood",
      label: "Moods* (multiple)",
      accessor: "mood",
      minWidth: 150,
      dataType: DataTypes.MULTI_SELECT,
      datatip: `<div style="width: 20em">
      What is the mood of the loop?
      </div>`,
      options: [],
    },
    {
      id: "key",
      label: "Key*",
      accessor: "key",
      minWidth: 100,
      dataType: DataTypes.MULTI_SELECT,
      datatip: `<div style="width: 20em">
      What is the key of the loop? <br/>
      Options are shown in sharp notation, for example: A# instead of Bb <br/>
      A key without any "m" ex: "a" indicates that the loop is in a major key, i.e "A major" for the example. <br/> A key with an "m" after it ex: "am" indicates that the loop is in a minor key, i.e "A minor" for the example. <br/>
      If there is no specific key (ex for untuned drums or SFX), please select "atonal".
      </div>`,
      options: []
    },

    {
      id: "genre",
      label: "Genre*",
      accessor: "genre",
      minWidth: 200,
      dataType: DataTypes.MULTI_SELECT,
      options: [],
    },
    {
      id: "recommended section",
      label: "Recommended Section*",
      accessor: "recommended section",
      minWidth: 250,
      dataType: DataTypes.MULTI_SELECT,
      datatip: `<div style= "width: 40em !important" >
        What section of the track would this loop be best suited in?
        <ul>
          <li>Intro: loop would suit the first few bars of the track</li>
          <li>Verse: loop would suit the verse, the part that contains the details of a track: the story, the events, and the moods.</li>
          <li>buildup, progression: loop would suit the buildup to a chorus or a drop</li>
          <li>Chorus, drop: loop would suit the main part of the track, the chorus, or the drop.</li> 
          <li>Break, bridge: loop would suit a part after the chorus</li>
          <li>Outro: The last few bars of the track</li>
          <li>Percussion: Loop is a percussion loop and will suit most sections of a track</li>
          <li>Ambience: SFX, ambience, vinyl noise, foley etc</li>
          <li>Transitions, SFX: One shots like drum fills, risers and impacts</li>
        </ul>
      </div>`,
      options: [],
    },
    {
      id: "tempo",
      label: "Tempo (BPM)*",
      accessor: "tempo",
      minWidth: 100,
      dataType: DataTypes.NUMBER,
      datatip: `<div style="width: 20em">
      What is the tempo of the loop in BPM?
      </div>`,
      options: [],
    },
    {
      id: "length(number of bars)",
      label: "Length (bars)*",
      accessor: "length(number of bars)",
      minWidth: 100,
      dataType: DataTypes.NUMBER,
      options: [],
      datatip: `<div style="width: 20em">
      Length of the loop in bars. If the length of the loop is missing, either replace the sample with a file with the tempo mentioned in the filename or enter the length in bars directly.
      </div>`
    },

    {
      id: "time signature",
      label: "Time Signature",
      accessor: "time signature",
      minWidth: 100,
      dataType: DataTypes.TEXT,
      datatip: `<div style="width: 20em">
      What is the time signature of the loop? 4/4 or 3/4?
      </div>`,
      options: [],
    },
    {
      id: "frequency range",
      label: "Frequency Range*",
      accessor: "frequency range",
      minWidth: 200,
      dataType: DataTypes.SELECT,
      datatip: `<div style="width: 20em">
      What is the frequency range of the loop? This is automatically calculated during upload but you can change it if you believe it is incorrect.
      <ul>
      <li> Low: Average frequency range is lower than 500Hz</li>
      <li> Mid: Average frequency range is between 500Hz and 5000Hz</li>
      <li> High: Average frequency range is higher than 5000Hz</li>
      </ul>
      </div>`,
      options: selectMaker(
        ["low", "mid", "high"].map((tag) => {
          return { "frequency range": tag };
        }),
        "frequency range"
      ),
    },

    {
      id: "scale",
      label: "Scale",
      accessor: "scale",
      minWidth: 100,
      dataType: DataTypes.TEXT,
      datatip: `<div style="width: 20em">
      What scale is the loop in?
      </div>`,
      options: [],
    },
  ];
  const data1 = [
    {
      filename: "Piano Chords/sad-130-Db-Indie ballad.wav",
      "audio type": "loops",
      "instrument role": "chords",
      instrument: "piano",
      "length(number of bars)": 4,
      "frequency range": "mid",
      tempo: 130,
      key: "c#",
      "time signature": "4/4",
      genre: "indie",
      mood: "sad",
      "if any other mood, please mention here": "",
      "if any other mood, please mention here": "",
      moodintensity: "",
      region: "western",
      music_theory: "western",
      scale: "",
      "recommended section": "verse",
      activity_theme: "",
      lflag: "Loops Perfectly",
    },
  ];
  const data2 = [
    {
      filename: "piano_melody_80_D_calm.wav",
      "audio type": "loops",
      "instrument role": "melody",
      instrument: "piano",
      "length(number of bars)": 4,
      "frequency range": "mid",
      tempo: 85,
      key: "dm",
      "time signature": "4/4",
      genre: "cinematic",
      mood: "calm",
      "if any other mood, please mention here": "",
      moodintensity: "",
      region: "western",
      music_theory: "western",
      scale: "",
      "recommended section": "verse",
      activity_theme: "",
      lflag: "Needs Trim, Did You Mean 86BPM For 8 Bars, ----- Length = 22.326s, Indicated Tempo = 80.0BPM, Bars = 7.907.",
    },
    {
      filename: "Electronic/Piano Chords/happy-120-Gm-minimal-techno.wav",
      "audio type": "loops",
      "instrument role": "chords",
      instrument: "piano",
      "length(number of bars)": 4,
      "frequency range": "mid",
      tempo: 120,
      key: "gm",
      "time signature": "4/4",
      genre: "electronic",
      mood: "happy",
      "if any other mood, please mention here": "",
      moodintensity: "",
      region: "western",
      music_theory: "western",
      scale: "",
      "recommended section": "verse",
      activity_theme: "",
      lflag: "Loops Perfectly",
    },
    {
      filename: "Cinematic/Calm Marimba loops/staccato-130-emajor.wav",
      "audio type": "loops",
      "instrument role": "melody",
      instrument: "piano",
      "length(number of bars)": 4,
      "frequency range": "mid",
      tempo: 130,
      key: "e",
      "time signature": "4/4",
      genre: "cinematic",
      mood: "calm",
      "if any other mood, please mention here": "",
      moodintensity: "",
      region: "western",
      music_theory: "western",
      scale: "",
      "recommended section": "verse,chorus",
      activity_theme: "",
      lflag: "Loops Perfectly",
    },
    {
      filename: "Cinematic/Calm Marimba loops/swing-130-e.wav",
      "audio type": "loops",
      "instrument role": "melody",
      instrument: "piano",
      "length(number of bars)": 4,
      "frequency range": "mid",
      tempo: 130,
      key: "e",
      "time signature": "4/4",
      genre: "cinematic",
      mood: "calm",
      "if any other mood, please mention here": "",
      moodintensity: "",
      region: "western",
      music_theory: "western",
      scale: "",
      "recommended section": "verse,chorus",
      activity_theme: "",
      lflag: "Loops Perfectly",
    },
  ];
  const [grid1, setGrid1] = useState(data1);
  const [grid2, setGrid2] = useState(data2);

  const [state1, dispatch] = useReducer(reducer, {
    columns: columns,
    data: grid1,
    skipReset: true,
  });

  const [state2, dispatch1] = useReducer(reducer, {
    columns: columns,
    data: grid2,
    skipReset: true,
  });

  useEffect(() => {
    setGrid1(state1.data);
    setGrid2(state2.data);
  }, [state1.data, state2.data, state1, state2]);

  return (
    <>
      <div className="">
        <ReactTooltip className="tooltip" />
        <Collapsible title="Submission Guidelines for submitting loops" initOpen={true}>
          <div className="">
            {/* <h1>Submission Guidelines</h1> */}
            <div className="imp">Export your samples with these settings: </div>

            <div className="imp">
              <ul>
                <li>
                  File Format: <b>WAV</b>
                </li>
                <li>
                  True Peak: <b>-3db</b>{" "}
                </li>
                <li>
                  Sample Rate: <b>44100 Hz</b>
                </li>
                <li>
                  Bitrate: Recommended - <b>32bit - 24bit</b>, Minimum - <b>16bit</b>{" "}
                </li>
              </ul>
            </div>
            <div className="imp">
              Make sure you've tagged your loop samples with the right BPM and
              that they loop perfectly. Make sure to trim any reverb tails or
              trailing silences in the final exports.
            </div>
            <div className="imp">
              Collect your exports, categorise them into different folders (see
              documentation below), zip them and upload. Try to avoid uploading all your samples in a single zip file, large file uploads can be unreliable. Splitting your submission into smaller groups of 100-200 samples will make it less overwhelming for you to fill and tag all of them. {" "}
            </div>

            <div className="imp">
              This webapp will <u>autofill any tags that you mention in your filename or in the folder names.</u>&nbsp;
              Any tags that are not autofilled can be filled manually using the tag input fields.
            </div>
            <div className="label" style={{ fontSize: "14pt" }}>
              Separate tags in your filenames with one any of these separators <br />
              <span style={{ paddingInline: "5px" }}>Space " " </span><br />
              <span style={{ paddingInline: "5px" }}>Comma "," </span><br />
              <span style={{ paddingInline: "5px" }}>Hyphen "-" </span><br />
              <span style={{ paddingInline: "5px" }}>Underscore "_" </span><br />
            </div>
            <div className="container">
              <Button
                className="submitmusic"
                variant="primary"
                onClick={buttonredirect}
                size="lg"
                style={{ margin: "20px" }}
              >
                Submit your music
              </Button>
            </div>
            <h1>Tagging and organising your samples</h1>
            <h2>1. File Names</h2>
            <p>
              Lets say you've written a 16 bar piano melody, in D Major, at 130
              BPM. You describe its mood as dreamy or calm. You could name your
              sample like this:
              <b>
                <h5 className="sample">"piano_melody_130_D_calm.wav"</h5>
              </b>
              <br />
              The order of these tags: "piano", "melody", "130", "D", "calm" does
              not matter,
              <b>
                {" "}
                as long as you mention these tags somewhere, separated by a comma
                ",", a hypen "-", or an underscore "_", they will get autofilled.
              </b>
            </p>
            <h3>Autofilled tags for "piano_melody_130_D_calm.wav"</h3>
            <p>
              Go through each of these fields. Select menu fields are initialised
              with a few default values.
            </p>
            <p>
              Click on a column header to change its property type. Fields marked
              with "*" are mandatory.
            </p>
            <div
              style={{ borderWidth: "1px", width: "100%" }}
              className="react-table-container"
            >
              <div className="overflow-y-hidden" style={{ marginBlock: "1%" }}>
                <Table
                  columns={columns}
                  data={grid1}
                  dispatch={dispatch}
                  skipReset={state1.skipReset}
                />
                <div id="popper-portal"></div>
              </div>
            </div>
            <h2>2. Folder Structure</h2>
            <p>
              If you have enough loops that share a common tag, put them in a
              folder, and give each sample a unique name, for example:
            </p>
            {/* <div>
              <p>
                ├──<b>Submission.zip</b>
              </p>
              <p>
                │&nbsp;&nbsp;&nbsp;&nbsp;├──<b> Piano Chords</b>
              </p>
              <p>
                │&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;├──
                sad-130-Db-Indie ballad.wav
              </p>
              <p>
                │&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;├──
                happy-120-Gm-minimal-techno.wav
              </p>
              <p>
                │&nbsp;&nbsp;&nbsp;&nbsp;├──<b> Calm Marimba loops</b>
              </p>
              <p>
                │&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;├──
                staccato-130-emajor.wav
              </p>
              <p>
                │&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;├──
                swing-130-e.wav
              </p>
            </div> */}

            <div
              style={{
                lineHeight: "1.2", color: "white", fontSize: "1.25rem", paddingLeft: "0.5rem", fontFamily: "monospace"

              }}
            >
              Submission.zip<br />
              ├──&nbsp;Electronic<br />
              │&nbsp;&nbsp;&nbsp;├──&nbsp;Piano Chords<br />
              │&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;├──&nbsp;sad-130-Db-Indie ballad.wav<br />
              │&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;├──&nbsp;happy-120-Gm-minimal-techno.wav<br />
              │&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;...<br />
              │&nbsp;&nbsp;&nbsp;...<br />
              ├──&nbsp;Cinematic<br />
              │&nbsp;&nbsp;&nbsp;├──&nbsp;Calm Marimba loops<br />
              │&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;├──&nbsp;staccato-130-emajor.wav<br />
              │&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;├──&nbsp;swing-130-e.wav<br />
              │&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;...<br />
              ├──&nbsp;piano_melody_80_D_calm.wav<br />


            </div>

            <h3>Autofilled tags for "Submission.zip"</h3>
            <p>
              Note that tags mentioned in the folder names also get autofilled.
              This will help you organise your samples.
            </p>
            <div
              style={{ borderWidth: "1px", width: "100%" }}
              className="react-table-container"
            >
              <div className="overflow-y-hidden" style={{ marginBlock: "1%" }}>
                <Table
                  columns={columns}
                  data={grid2}
                  dispatch={dispatch1}
                  skipReset={state2.skipReset}
                />
                <div id="popper-portal"></div>
              </div>
            </div>
          </div>
        </Collapsible>

      </div>
    </>
  );
};

export default Instructions;
