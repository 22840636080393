import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import "../App.css";
import logo from "../img/logo.png";
import menu from "../img/menu.png";
import Sidebar from "../Components/sideabar";
import { useAuth0 } from "@auth0/auth0-react";

function Navigation(props) {
  const { isAuthenticated, logout, user, loginWithRedirect } = useAuth0();
  const [showSidebar, setShowSidebar] = useState(true);
  const toggleSideBar = () => {
    setShowSidebar(!showSidebar);
  };
  useLayoutEffect(() => {
    toggleSideBar();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (showSidebar) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  });

  return (
    <>
      <header>
        <div>
          <Link to="/">
            <img src={logo} alt="beatoven.ai" className="logo" />
          </Link>
        </div>
        <div>
          <img
            src={menu}
            alt="menu"
            className="toggle"
            onClick={toggleSideBar}
          />
        </div>
        <div className="navlinks">
          {isAuthenticated && (
            <ul>
              <li>
                <NavLink to="/submitmusic" className="links">
                  Submit Music
                </NavLink>
              </li>
              <li>
                <NavLink to="/instructions" className="links">
                  Instructions
                </NavLink>
              </li>
              <li>
                <NavLink to="/profile" className="links">
                  {/* <img src={user.picture} alt={user.name} className="profile" /> */}
                  {user.nickname}
                </NavLink>
              </li>
              <li>
                <Link to="/" className="links red" onClick={() => logout()}>
                  Logout
                </Link>
              </li>
            </ul>
          )}
          {!isAuthenticated && (
            <ul>
              <li>
                <div className="navlink link green"
                  style={{
                    cursor: "pointer",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                  onClick={loginWithRedirect}>
                  Login
                </div>
              </li>
            </ul>
          )}
        </div>
      </header>
      {showSidebar && (
        <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      )}
    </>
  );
}

export default withRouter(Navigation);
