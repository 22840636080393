import { useState, useEffect, useReducer, useRef } from "react";
import "../style.css";
import "../App.css";
import _ from "lodash";
import Table2 from "./Table2";
import {
    API,
    ActionTypes,
    DataTypes,
    selectMaker,
    upload,
    randomColor,
} from "../utils";
import ReplaceIcon from "../img/ReplaceIcon";

import update from "immutability-helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function user_friendly_lflag(status, kwargs) {
    const flags = {
      "badFile": "Bad file, please use the replace button to replace the file.",
      "silent": "File is silent/empty, please use the replace button to replace the file.",
      "sampleRateError": `Sample rate is ${kwargs && kwargs.sample_rate ? kwargs.sample_rate : 0}, please convert to 44100.`,
      "needsTrim": `Needs trim, Did you mean ${kwargs && kwargs.tempo_suggestion ? kwargs.tempo_suggestion : 0} BPM for ${kwargs && kwargs.bar_suggestion ? kwargs.bar_suggestion : 0} bars, ----- length = ${kwargs && kwargs.slength ? Math.round(kwargs.slength, 3) : 0}s, indicated tempo = ${kwargs && kwargs.tempo ? kwargs.tempo : 0} BPM, bars = ${kwargs && kwargs.bars ? Math.round(kwargs.bars, 3) : 0}.`,
      "durationError": "Each stem must be at least 2min 30s long.",
    };
  
    return flags[status];
  }

function getTempo(bars, length) {
    return (4 * 60 * bars) / length;
}

function getNewStatus(row, tempo, length) {
    
    const TEMPO_TOLERANCE = 0.02;
    let lflag = row.lflag;
    let bars = (length * tempo) / (4 * 60);
    let message = {
        "text": "",
        "metadata": {
            "slength": length,
            "bars": bars,
            "tempo": tempo,
            "tempo_suggestion": tempo,
            "bar_suggestion": 0,
        },
        "status": "",
    };
    if (length < 150) {
        message["status"] = "durationError";
        message["text"] += user_friendly_lflag("durationError");
    }

    if (Math.abs(Math.round(bars) - bars) > TEMPO_TOLERANCE) {
        let bar_suggestion = 4;
        let tempo_suggestion = getTempo(bar_suggestion, length);

        while (
            (
                Math.abs(tempo_suggestion
                - Math.round(tempo_suggestion)) > TEMPO_TOLERANCE
                && tempo_suggestion < 180
            ) || (
                tempo_suggestion < 75
            )
        ) {
            bar_suggestion += 4
            tempo_suggestion = getTempo(bar_suggestion, length)
        }
        if (tempo_suggestion > 180){
            tempo_suggestion /= 2
            bar_suggestion /= 2 
        }

        tempo_suggestion = Math.round(tempo_suggestion)
         
        message["metadata"]["tempo_suggestion"] = tempo_suggestion
        message["metadata"]["bar_suggestion"] = bar_suggestion
        message["status"] = "needsTrim";
        message["text"] += user_friendly_lflag("needsTrim", message["metadata"]);
    }
    
    return message;
}

function reducer(state, action) {
    switch (action.type) {
        case ActionTypes.ADD_OPTION_TO_COLUMN:
            const optionIndex = state.columns.findIndex(
                (column) => column.id === action.columnId
            );
            return update(state, {
                skipReset: { $set: true },
                columns: {
                    [optionIndex]: {
                        options: {
                            $push: [
                                {
                                    label: action.option,
                                    backgroundColor: action.backgroundColor,
                                },
                            ],
                        },
                    },
                },
            });
        case ActionTypes.ADD_ROW:
            return update(state, {
                skipReset: { $set: true },
                data: { $push: [{}] },
            });
        case ActionTypes.IMPORT:
            return update(state, {
                skipReset: { $set: true },
                data: { $set: action.newDf },
            });
        case ActionTypes.ADD_ROWNEW:
            return update(state, {
                skipReset: { $set: true },
                data: { $push: action.rowd },
            });
        case ActionTypes.ENABLE_RESET:
            // console.log("enable reset", state);
            const optionIndex2 = state.columns.findIndex(
                (column) => column.id === "scale"
            );
            return update(state, {
                skipReset: { $set: true },
                columns: {
                    [optionIndex2]: {
                        options: {
                            $push: [
                                {
                                    label: "",
                                    backgroundColor: "",
                                },
                            ],
                        },
                    },
                },
            });
        case ActionTypes.UPDATE_CELL:
            // handle lflag update here
            return update(state, {
                skipReset: { $set: true },
                data: {
                    [action.rowIndex]: {
                        [action.columnId]: { $set: action.value },
                    },
                },
            });
        case ActionTypes.UPDATE_ROW:
            // console.log("updating row ", action, state.data);
            return update(state, {
                skipReset: { $set: true },
                data: {
                    [action.index]: { $set: action.payload },
                },
            });
        // WIP:
        // case ActionTypes.UPDATE_COLUMN_TYPE:
        //   const typeIndex = state.columns.findIndex(
        //     (column) => column.id === action.columnId
        //   );
        //   switch (action.dataType) {
        //     case DataTypes.NUMBER:
        //       if (state.columns[typeIndex].dataType === DataTypes.NUMBER) {
        //         return state;
        //       } else {
        //         return update(state, {
        //           skipReset: { $set: true },
        //           columns: { [typeIndex]: { dataType: { $set: action.dataType } } },
        //           data: {
        //             $apply: (data) =>
        //               data.map((row) => ({
        //                 ...row,
        //                 [action.columnId]: isNaN(row[action.columnId])
        //                   ? ""
        //                   : Number.parseInt(row[action.columnId]),
        //               })),
        //           },
        //         });
        //       }
        //     case DataTypes.SELECT:
        //       if (state.columns[typeIndex].dataType === DataTypes.SELECT) {
        //         return state;
        //       } else if (
        //         state.columns[typeIndex].dataType === DataTypes.MULTI_SELECT
        //       ) {
        //         return update(state, {
        //           skipReset: { $set: true },
        //           columns: {
        //             [typeIndex]: {
        //               dataType: { $set: action.dataType },
        //               options: {
        //                 $apply: (options) => {
        //                   let temp = options;
        //                   //consolelog(temp);
        //                   return temp.map((lab) => {
        //                     return {
        //                       label: lab,
        //                       backgroundColor: randomColor(),
        //                     };
        //                   });
        //                 },
        //               },
        //             },
        //           },
        //         });
        //       } else {
        //         let options = selectMaker(state.data, action.columnId);

        //         return update(state, {
        //           skipReset: { $set: true },
        //           columns: {
        //             [typeIndex]: {
        //               dataType: { $set: action.dataType },
        //               options: { $push: options },
        //             },
        //           },
        //         });
        //       }
        //     case DataTypes.MULTI_SELECT:
        //       if (
        //         state.columns[typeIndex].dataType === DataTypes.MULTI_SELECT ||
        //         action.columnId === "time signature" ||
        //         action.columnId === "tempo" ||
        //         action.columnId === "length(number of bars)"
        //       ) {
        //         return state;
        //       } else {
        //         return update(state, {
        //           skipReset: { $set: true },
        //           columns: {
        //             [typeIndex]: {
        //               dataType: { $set: action.dataType },
        //               options: {
        //                 $apply: (options) => {
        //                   //consolelog(options);
        //                   let temp = options.map((option) => {
        //                     if (option.label !== "null") {
        //                       return option.label;
        //                     }
        //                   });
        //                   return temp.toString();
        //                 },
        //               },
        //             },
        //           },
        //         });
        //       }
        //     case DataTypes.TEXT:
        //       if (state.columns[typeIndex].dataType === DataTypes.TEXT) {
        //         return state;
        //       } else if (state.columns[typeIndex].dataType === DataTypes.SELECT) {
        //         return update(state, {
        //           skipReset: { $set: true },
        //           columns: {
        //             [typeIndex]: {
        //               dataType: { $set: action.dataType },
        //               options: { $apply: (opts) => _.uniqBy(opts, "label") },
        //             },
        //           },
        //         });
        //       } else if (
        //         state.columns[typeIndex].dataType === DataTypes.MULTI_SELECT
        //       ) {
        //         return update(state, {
        //           skipReset: { $set: true },
        //           columns: { [typeIndex]: { dataType: { $set: action.dataType } } },
        //           data: {
        //             $apply: (data) =>
        //               data.map((row) => ({
        //                 ...row,
        //                 [action.columnId]: !(
        //                   typeof row[action.columnId] === "string" &&
        //                   row[action.columnId].length > 0
        //                 )
        //                   ? row[action.columnId]
        //                   : "row[action.columnId]",
        //               })),
        //           },
        //         });
        //       } else {
        //         return update(state, {
        //           skipReset: { $set: true },
        //           columns: { [typeIndex]: { dataType: { $set: action.dataType } } },
        //           data: {
        //             $apply: (data) =>
        //               data.map((row) => ({
        //                 ...row,
        //                 [action.columnId]: row[action.columnId] + "",
        //               })),
        //           },
        //         });
        //       }
        //     default:
        //       return state;
        //   }
        default:
            return state;
    }
}

function EditableTable(props) {
    const submissionId = props.submissionId;
    const email = props.email;
    const submissionDefaults = props.submissionDefaults;
    const [replacedRow, setReplacedRow] = useState();
    const [rowData, setRowData] = useState();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const inputFile = useRef();
    const [replacedRowIndex, setReplacedRowIndex] = useState();
    // const [saved, setSaved] = useState(props.saved);

    const openInputbox = () => {
        inputFile.current.click();
    };
    const check = (row) => {
        setRowData(row);
        setReplacedRowIndex(row.id);
        openInputbox();
    };

    function MakeData(grid, submissionId) {
        let df = grid.data;
        let columns = [
            {
                id: "replace",
                label: "Replace",
                width: 10,
                sticky: "left",
                datatip: "Click to replace the audio file for a row",
                Cell: (row) => {
                    return (
                        <>
                            <div
                                className="cell-padding d-flex cursor-default align-items-center flex-1"
                                style={{ minHeight: "60px" }}
                            >
                                <button
                                    className="font-weight-400 d-inline-block color-grey-800 border-radius-sm text-transform-capitalize submitmusic"
                                    style={{
                                        fontSize: "14px",
                                        padding: "5px 5px",
                                        fontFamily: "inherit",
                                    }}
                                    onClick={() => {
                                        check({
                                            id: row.row.id,
                                            path: row.row.original.filename,
                                        });
                                    }}
                                >
                                    <ReplaceIcon />
                                </button>
                            </div>
                        </>
                    );
                },
            },
            {
                id: "no",
                label: " #",
                dataType: DataTypes.STATIC_NUMBER,
                width: 10,
                options: [],
                sticky: "left",
                datatip: "Row number",
                Cell: (row) => {
                    return (
                        <>
                            <div
                                className="srno cell-padding d-flex cursor-default align-items-center flex-1"
                                style={{
                                    minHeight: "60px",
                                    justifyContent: "center",
                                }}
                            >
                                <div
                                    className="font-weight-400 d-inline-block border-radius-sm text-transform-capitalize"
                                    style={{
                                        fontSize: "18px",
                                        padding: "5px 5px",
                                        fontFamily: "inherit",
                                        whiteSpace: "break-spaces",
                                        // color: rowValid ? "red" : "black",
                                    }}
                                >
                                    {row.row.index + 1}
                                </div>
                            </div>
                        </>
                    );
                },
            },
            {
                id: "preview",
                label: "Preview",
                width: 130,
                sticky: "left",
                datatip: "Preview your audio files",
                Cell: (row) => {
                    return (
                        <>
                            <div
                                style={{
                                    overflowX: "hidden",
                                    overflowY: "hidden",
                                }}
                            >
                                <audio
                                    src={
                                        API.BASE_URL +
                                        API.GET_AUDIO +
                                        email +
                                        "/" +
                                        submissionDefaults.submissionName +
                                        "/" +
                                        submissionId +
                                        "/" +
                                        "samples" +
                                        "/" +
                                        encodeURIComponent(
                                            row.row.original.filename
                                        )
                                    }
                                    id = {row.row.original.filename}
                                    controls
                                    controlsList="nodownload noplaybackrate"
                                    preload="metadata"
                                />
                            </div>
                        </>
                    );
                },
            },
            {
                id: "filename",
                label: "File Name*",
                accessor: "filename",
                minWidth: 300,
                dataType: DataTypes.STATIC,
                sticky: "left",
                options: [],
                datatip: "Filename of your wav file",
            },
            {
                id: "lflag",
                label: "Status",
                accessor: "lflag",
                minWidth: 130,
                sticky: "left",
                datatip: `<div style="width: 20em">
        <ul>
          <li>This column shows if your audio file loops perfectly or if it might need trimming.</li>
          <li>If the cell says <span style="background-color: black; font-weight: bold">Needs trim, Did you mean XYZ BPM for A bars?</span>, it means that the tempo you mentioned might not be right for a whole number of bars (1 or 2 or 4 or 8 bars for example).</li>
          <li>It also shows the calculation that was done to figure out the number of bars, given the length in seconds of the audio file and the tempo indicated in the filename.</li>
          <li>No. of beats = (BPM * length)/60s</li>
          <li>No. of bars = (No. of beats)/(4 beats per bar)</li>
        </ul>
      </div>`,
                Cell: (row) => {
                    let lflag = row.row.original.lflag;
                    let bars = 0;
                    if (lflag.includes("Needs trim")) {
                        let bpm = row.row.original.tempo;
                        let length = Number(
                            lflag.split("----- length = ")[1].split("s,")[0]
                        );
                        bars = (length * bpm) / (60 * 4);
                        // bars = bars.toFixed(2);
                    } else {
                        bars = row.row.original["length(number of bars)"];
                    }
                    // if (Math.abs(Math.round(bars) - bars) < 0.015 && bars !== 0 && row.row.original["length(number of bars)"] !== null) {
                    //   if (!lflag.includes("mismatch") and !lflag.includes("Needs trim") {
                    //     lflag = "Loops Perfectly";
                    //   }
                    // }
                    return (
                        <>
                            <div
                                className="cell-padding d-flex cursor-default align-items-center flex-1"
                                style={{ minHeight: "60px" }}
                            >
                                <div
                                    className="font-weight-400 d-inline-block border-radius-sm text-transform-capitalize"
                                    style={{
                                        fontSize: "12px",
                                        padding: "5px 5px",
                                        fontFamily: "inherit",
                                        whiteSpace: "break-spaces",
                                    }}
                                >
                                    {lflag}
                                </div>
                            </div>
                        </>
                    );
                },
            },
            {
                id: "audio type",
                label: "Audio Type*",
                accessor: "audio type",
                minWidth: 100,
                dataType: DataTypes.SELECT,
                options: selectMaker(
                    [
                        "loops",
                        "stem",
                        "sfx",
                        "one shot",
                        "mixdown",
                        "master",
                    ].map((tag) => {
                        return { "audio type": tag };
                    }),
                    "audio type"
                ),
                datatip: `<div style="width: 20em">
          <ul>
            <li>loops: audio that loops</li>
            <li>one shot: one shot chords or other non-looping samples</li>
            <li>sfx: sfx like risers or impacts</li>
          </ul>
        </div>`,
            },
            {
                id: "instrument role",
                label: "Instrument Role*",
                accessor: "instrument role",
                minWidth: 250,
                dataType: DataTypes.MULTI_SELECT,
                datatip: `<div style="width: 20em">
        What general role would this loop play in a track?
        <ul>
          <li>Bass: Synth Basslines, 808s, Bass guitars etc</li>
          <li>Chords: Chord loops, Pad loops, something that would provide a foundation for a track</li>
          <li>Melody: A lead synth melody, a piano melody, etc </li>
          <li>Percussion: full drum loops, top loops, other percussion loops like tabla etc</li>
          <li>SFX: Transitions, Fills, or other SFX like risers and impacts</li>
        </ul>
      </div>`,
                options: [],
            },
            {
                id: "instrument",
                label: "Instrument*",
                accessor: "instrument",
                minWidth: 100,
                dataType: DataTypes.MULTI_SELECT,
                datatip: `<div style="width: 20em">
        What instruments were used in the loop?<br />If you cannot find the exact instrument please select the instrument that sounds closest to the one you used.<br />For example, if you used tabla, select percussion. If you used a saxophone, select brass, and so on.
        </div>`,
                options: [],
            },
            {
                id: "mood",
                label: "Moods* (multiple)",
                accessor: "mood",
                minWidth: 150,
                dataType: DataTypes.MULTI_SELECT,
                datatip: `<div style="width: 20em">
        What is the mood of the loop?
        </div>`,
                options: [],
            },
            {
                id: "key",
                label: "Key*",
                accessor: "key",
                minWidth: 100,
                dataType: DataTypes.MULTI_SELECT,
                datatip: `<div style="width: 20em">
        What is the key of the loop? <br/>
        Options are shown in sharp notation, for example: A# instead of Bb <br/>
        A key without any "m" ex: "a" indicates that the loop is in a major key, i.e "A major" for the example. <br/> A key with an "m" after it ex: "am" indicates that the loop is in a minor key, i.e "A minor" for the example. <br/>
        If there is no specific key (ex for untuned drums or SFX), please select "atonal".
        </div>`,
                options: selectMaker(df, "key"),
            },
            {
                id: "genre",
                label: "Genre*",
                accessor: "genre",
                minWidth: 200,
                dataType: DataTypes.MULTI_SELECT,
                options: [],
            },
            {
                id: "recommended section",
                label: "Recommended Section*",
                accessor: "recommended section",
                minWidth: 250,
                dataType: DataTypes.MULTI_SELECT,
                datatip: `<div style= "width: 40em !important" >
          What section of the track would this loop be best suited in?
          <ul>
            <li>Intro: loop would suit the first few bars of the track</li>
            <li>Verse: loop would suit the verse, the part that contains the details of a track: the story, the events, and the moods.</li>
            <li>buildup, progression: loop would suit the buildup to a chorus or a drop</li>
            <li>Chorus, drop: loop would suit the main part of the track, the chorus, or the drop.</li> 
            <li>Break, bridge: loop would suit a part after the chorus</li>
            <li>Outro: The last few bars of the track</li>
            <li>Percussion: Loop is a percussion loop and will suit most sections of a track</li>
            <li>Ambience: SFX, ambience, vinyl noise, foley etc</li>
            <li>Transitions, SFX: One shots like drum fills, risers and impacts</li>
          </ul>
        </div>`,
                options: [],
            },
            {
                id: "tempo",
                label: "Tempo (BPM)*",
                accessor: "tempo",
                minWidth: 100,
                dataType: DataTypes.NUMBER,
                datatip: `<div style="width: 20em">
        What is the tempo of the loop in BPM?
        </div>`,
                options: [],
            },
            {
                id: "length(number of bars)",
                label: "Length (bars)*",
                accessor: "length(number of bars)",
                minWidth: 100,
                dataType: DataTypes.NUMBER,
                options: [],
                datatip: `<div style="width: 20em">
        Length of the loop in bars. If the length of the loop is missing, either replace the sample with a file with the tempo mentioned in the filename or enter the length in bars directly.
        </div>`,
                // Cell: (row) => {
                //   let lflag = row.row.original.lflag;
                //   let bars = 0;
                //   if (lflag.includes("Needs trim")) {
                //     let bpm = row.row.original.tempo;
                //     let length = Number(lflag.split("----- length = ")[1].split("s,")[0]);
                //     bars = (length * bpm) / (60 * 4);
                //     bars = bars.toFixed(2);
                //     if (bars != Number(row.row.original["length(number of bars)"])) {
                //       dispatch({
                //         type: ActionTypes.UPDATE_CELL,
                //         columnId: "length(number of bars)",
                //         rowIndex: row.row.index,
                //         value: bars
                //       });
                //     }
                //   } else {
                //     bars = row.row.original["length(number of bars)"];
                //   }

                //   if (row.row.original["length(number of bars)"] == null) {
                //     return(
                //       <>
                //         <div
                //           className="cell-padding d-flex cursor-default align-items-center flex-1"
                //           style={{ minHeight: "60px" }}
                //         >
                //           <div
                //             className="font-weight-400 d-inline-block border-radius-sm text-transform-capitalize"
                //             style={{
                //               fontSize: "14px",
                //               padding: "5px 5px",
                //               fontFamily: "inherit",
                //               whiteSpace: "break-spaces",
                //             }}
                //           >
                //             {/* {bars} */}

                //             <input
                //               className="form-control"
                //               type="number"
                //               placeholder=" Set Length"
                //               style={{
                //                 width: "100px",
                //                 height: "30px",
                //                 fontSize: "14px",
                //                 padding: "5px 5px",
                //                 fontFamily: "inherit",
                //                 whiteSpace: "break-spaces",
                //               }}

                //               onChange={(e) => {
                //                 dispatch({
                //                   type: ActionTypes.UPDATE_CELL,
                //                   columnId: "length(number of bars)",
                //                   rowIndex: row.row.index,
                //                   value: e.target.value
                //                 });
                //               }
                //               }
                //             />
                //           </div>
                //         </div>
                //       </>)
                //   }

                //   return (
                //     <>
                //       <div
                //         className="cell-padding d-flex cursor-default align-items-center flex-1"
                //         style={{ minHeight: "60px" }}
                //       >
                //         <div
                //           className="font-weight-400 d-inline-block border-radius-sm text-transform-capitalize"
                //           style={{
                //             fontSize: "14px",
                //             padding: "5px 5px",
                //             fontFamily: "inherit",
                //             whiteSpace: "break-spaces",
                //           }}
                //         >
                //           {bars}
                //         </div>
                //       </div>
                //     </>
                //   );
                // }
            },
            {
                id: "activity_theme",
                label: "Description",
                accessor: "activity_theme",
                minWidth: 100,
                dataType: DataTypes.TEXT,
                datatip: `<div style="width: 20em">
        Describe the loop or stem in one sentence. For example: "A dark and moody synth bassline for a trap track. Mandatory only for stems."
        </div>`,
            },
            {
                id: "time signature",
                label: "Time Signature",
                accessor: "time signature",
                minWidth: 100,
                dataType: DataTypes.TEXT,
                datatip: `<div style="width: 20em">
        What is the time signature of the loop? 4/4 or 3/4?
        </div>`,
                options: [],
            },
        //     {
        //         id: "frequency range",
        //         label: "Frequency Range*",
        //         accessor: "frequency range",
        //         minWidth: 200,
        //         dataType: DataTypes.SELECT,
        //         datatip: `<div style="width: 20em">
        // What is the frequency range of the loop? This is automatically calculated during upload but you can change it if you believe it is incorrect.
        // <ul>
        // <li> Low: Average frequency range is lower than 500Hz</li>
        // <li> Mid: Average frequency range is between 500Hz and 5000Hz</li>
        // <li> High: Average frequency range is higher than 5000Hz</li>
        // </ul>
        // </div>`,
        //         options: selectMaker(
        //             ["low", "mid", "high"].map((tag) => {
        //                 return { "frequency range": tag };
        //             }),
        //             "frequency range"
        //         ),
        //     },

            {
                id: "scale",
                label: "Scale",
                accessor: "scale",
                minWidth: 100,
                dataType: DataTypes.TEXT,
                datatip: `<div style="width: 20em">
        What scale is the loop in?
        </div>`,
                options: [],
            },
            // {
            //   id: "mood intensity(1 - lowest, 5 - highest)",
            //   label: "Mood Intensity* (1-5)",
            //   accessor: "mood intensity(1 - lowest, 5 - highest)",
            //   minWidth: 100,
            //   dataType: DataTypes.SELECT,
            //   options: selectMaker(
            //     [1, 2, 3, 4, 5].map((tag) => {
            //       return { "mood intensity(1 - lowest, 5 - highest)": tag };
            //     }),
            //     "mood intensity(1 - lowest, 5 - highest)"
            //   ),
            // },
            // {
            //   id: "if any other mood, please mention here",
            //   label: "Secondary Moods",
            //   accessor: "if any other mood, please mention here",
            //   minWidth: 200,
            //   dataType: DataTypes.TEXT,
            //   options: [],
            // },

            // {
            //   id: "region",
            //   label: "Region",
            //   accessor: "region",
            //   minWidth: 100,
            //   dataType: DataTypes.TEXT,
            //   options: [],
            // },
            // {
            //   id: "music_theory",
            //   label: "Music Theory",
            //   accessor: "music_theory",
            //   minWidth: 100,
            //   dataType: DataTypes.MULTI_SELECT,
            //   options: [],
            // }

            // {
            //   id: 999999,
            //   width: 20,
            //   label: '+',
            //   disableResizing: true,
            //   dataType: 'null',
            // },
        ];
        // undoHistory WIP
        // let undoHistory = [{ columns: columns, data: df }];
        return {
            columns: columns,
            data: df,
            skipReset: true,
            // undoHistory: undoHistory,
        };
    }
    const [state, dispatch] = useReducer(
        reducer,
        MakeData(props.grid, submissionId)
    );
    // const tl = props.tl;

    useEffect(() => {
        props.fn(state);
    }, [state.data, props.grid, state]);

    // const importedCsv = () => {
    //   let ns = MakeData(props.newDf, submissionId);
    //   props.fn(ns);
    // };

    useEffect(() => {
        if (props.newDf) {
            dispatch({
                type: ActionTypes.IMPORT,
                newDf: JSON.parse(props.newDf).data,
            });
        }
    }, [props.newDf]);

    useEffect(() => {
        dispatch({
            type: ActionTypes.UPDATE_ROW,
            payload: replacedRow,
            index: parseInt(replacedRowIndex),
        });
    }, [replacedRow]);

    const selectFile = (event) => {
        setSelectedFiles(event.target.files);
    };

    const notify = (p) => toast("✔ " + p + " replaced successfully");

    const parseAddNewFileError = (error) => {
        console.log(error);
        if (
            error.response &&
            error.response.data &&
            error.response.data.reason
        ) {
            return error.response.data.reason;
        }
        else if (error.code === "ERR_NETWORK") {
            return "There was a network error. Please try again or contact support if the problem persists.";
        }
        else if (error.message) {
            return error.message;
        }

        return "Unknown error. Please try again.";
    };

    const addNewFile = async () => {
        if (selectedFiles.length > 0) {
            let currentFile = selectedFiles[0];
            await upload(
                API.REPLACE,
                currentFile,
                email,
                submissionId,
                submissionDefaults,
                props.totalLength,
                rowData.path
            )
                .then((response) => {
                    notify(rowData.path);
                    setReplacedRow(JSON.parse(response.data.meta.df).data[0]);
                    props.setTotalLength(response.data.meta.tl);
                })
                .catch((error) => {
                    console.log(error);
                    window.alert(
                        `Error uploading file: ${
                            currentFile.name
                        }\n${parseAddNewFileError(error)}`
                    );
                });
        }
    };

    useEffect(() => {
        addNewFile();
    }, [selectedFiles]);

    return (
        <div className="overflow-y-hidden">
            <Table2
                columns={state.columns}
                data={state.data}
                dispatch={dispatch}
                skipReset={state.skipReset}
                totalLength={props.totalLength}
                email={email}
                submissionId={submissionId}
                submissionDefaults={submissionDefaults}
                saved={props.saved}
                setTotalLength={props.setTotalLength}
            />
            <div id="popper-portal"></div>
            <div>
                <input
                    id="newrow"
                    type="file"
                    ref={inputFile}
                    style={{ display: "none" }}
                    onChange={selectFile}
                    accept="audio/wav, audio/midi"
                />
            </div>
        </div>
    );
}

export default EditableTable;
