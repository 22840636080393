import React, { useRef } from "react";
// import "../../styles/main.css";
import { Link, NavLink } from "react-router-dom";
import useOutsideClick from "./useOutsideClick";
import { useAuth0 } from "@auth0/auth0-react";

const Sidebar = ({ showSidebar, setShowSidebar }) => {
  const { isAuthenticated, logout, user } = useAuth0();
  const ref = useRef();

  useOutsideClick(ref, () => {
    setShowSidebar(!showSidebar);
  });

  return (
    <>
      <div className={`menu ${showSidebar ? "width:590px" : "width:0px"}`}>
        <div ref={ref}>
          {isAuthenticated && (
            <ul>
              <li>
                <NavLink to="/submitmusic" className="links">
                  Submit Music
                </NavLink>
              </li>
              <li>
                <NavLink to="/profile" className="links">
                  {user.nickname}
                </NavLink>
              </li>
              <li>
                <Link to="/" className="links" onClick={() => logout()}>
                  Logout
                </Link>
              </li>
            </ul>
          )}
          {!isAuthenticated && (
            <ul>
              <li>
                <Link to="/login" className="links">
                  Login
                </Link>
              </li>
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
