import React, { useState, useEffect } from "react";
import { usePopper } from "react-popper";
// import { grey } from "./colors";
// import ArrowUpIcon from "../img/ArrowUp";
// import ArrowDownIcon from "./img/ArrowDown";
// import ArrowLeftIcon from "./img/ArrowLeft";
// import ArrowRightIcon from "./img/ArrowRight";
// import TrashIcon from "./img/Trash";
import TextIcon from "../img/Text";
import MultiIcon from "../img/Multi";
import HashIcon from "../img/Hash";
// import PlusIcon from "../img/Plus";
import { DataTypes } from "../utils";
import _ from "lodash";

function getPropertyIcon(dataType) {
  switch (dataType) {
    case DataTypes.NUMBER:
      return <HashIcon />;
    case DataTypes.TEXT:
      return <TextIcon />;
    case DataTypes.SELECT:
      return <MultiIcon />;
    case DataTypes.MULTI_SELECT:
      return <MultiIcon />;
    default:
      return null;
  }
}

export default function Header(
  {
    column: { id, datatip, created, label, dataType, getResizerProps, getHeaderProps },
    setSortBy,
    dataDispatch,
  },
  HEADER_VIEW = false
) {
  const [expanded, setExpanded] = useState(created || false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  // const [inputRef, setInputRef] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom",
    strategy: "absolute",
  });
  const [header, setHeader] = useState(label);
  const [typeReferenceElement, setTypeReferenceElement] = useState(null);
  const [typePopperElement, setTypePopperElement] = useState(null);
  const typePopper = usePopper(typeReferenceElement, typePopperElement, {
    placement: "right",
    strategy: "fixed",
  });
  // const [showType, setShowType] = useState(false);
  // const buttons = [
  //   {
  //     onClick: (e) => {
  //       dataDispatch({
  //         type: ActionTypes.UPDATE_COLUMN_HEADER,
  //         columnId: id,
  //         label: header,
  //       });
  //       setSortBy([{ id: id, desc: false }]);
  //       setExpanded(false);
  //     },
  //     icon: <ArrowUpIcon />,
  //     label: "Sort ascending",
  //   },
  //   {
  //     onClick: (e) => {
  //       dataDispatch({
  //         type: ActionTypes.UPDATE_COLUMN_HEADER,
  //         columnId: id,
  //         label: header,
  //       });
  //       setSortBy([{ id: id, desc: true }]);
  //       setExpanded(false);
  //     },
  //     icon: <ArrowDownIcon />,
  //     label: "Sort descending",
  //   },
  // ];
  const propertyIcon = getPropertyIcon(dataType);

  // const types = [
  //   {
  //     onClick: (e) => {
  //       dataDispatch({
  //         type: "update_column_type",
  //         columnId: id,
  //         dataType: DataTypes.SELECT,
  //       });
  //       setShowType(false);
  //       setExpanded(false);
  //     },
  //     icon: <MultiIcon />,
  //     label: "Single Tag",
  //   },
  //   {
  //     onClick: (e) => {
  //       dataDispatch({
  //         type: "update_column_type",
  //         columnId: id,
  //         dataType: DataTypes.MULTI_SELECT,
  //       });
  //       setShowType(false);
  //       setExpanded(false);
  //     },
  //     icon: <> <MultiIcon /> <MultiIcon /> </>,
  //     label: "Multiple Tags",
  //   },
  //   {
  //     onClick: (e) => {
  //       dataDispatch({
  //         type: "update_column_type",
  //         columnId: id,
  //         dataType: DataTypes.TEXT,
  //       });
  //       setShowType(false);
  //       setExpanded(false);
  //     },
  //     icon: <TextIcon />,
  //     label: "Text",
  //   },
  //   {
  //     onClick: (e) => {
  //       dataDispatch({
  //         type: "update_column_type",
  //         columnId: id,
  //         dataType: DataTypes.NUMBER,
  //       });
  //       setShowType(false);
  //       setExpanded(false);
  //     },
  //     icon: <HashIcon />,
  //     label: "Number",
  //   },
  // ];

  // function handleKeyDown(e) {
  //   if (e.key === "Enter") {
  //     dataDispatch({
  //       type: "update_column_header",
  //       columnId: id,
  //       label: header,
  //     });
  //     setExpanded(false);
  //   }
  // }

  // // function handleChange(e) {
  // //   setHeader(e.target.value);
  // // }

  // function handleBlur(e) {
  //   e.preventDefault();
  //   dataDispatch({ type: "update_column_header", columnId: id, label: header });
  // }

  function getHeader() {
    if (HEADER_VIEW) {
      return (
        <>
          <div {...getHeaderProps()}
            data-tip={datatip}
            data-html={true}
            className="th noselect d-inline-block">
            <div
              className="th-content"
              onClick={() => setExpanded(true)}
              ref={setReferenceElement}
            >
              <span className="svg-icon svg-gray icon-margin">
                {propertyIcon}
              </span>
              {label}
            </div>
            <div {...getResizerProps()} className="resizer" />
          </div>
          {expanded && (
            <div className="overlay" onClick={() => setExpanded(false)} />
          )}
          {expanded && (
            <div
              ref={setPopperElement}
              style={{ ...styles.popper, zIndex: 3 }}
              {...attributes.popper}
            >
              <div
                className="bg-white shadow-5 border-radius-md"
                style={{
                  width: 200,
                }}
                onClick={() => setExpanded(false)}
              >
                <div
                  style={{
                    paddingTop: "0.75rem",
                    paddingLeft: "0.75rem",
                    paddingRight: "0.75rem",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="is-fullwidth"
                    style={{ marginBottom: 10, justifyContent: "center" }}
                  >
                    {/* <input
                      className="form-input"
                      ref={setInputRef}
                      type="text"
                      value={header}
                      style={{ width: "100%" }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onKeyDown={handleKeyDown}
                    /> */}
                    {header}
                  </div>
                  <span className="font-weight-600 font-size-75 color-grey-500 text-transform-uppercase">
                    Property Type
                  </span>
                </div>
                <div className="list-padding">
                  <button
                    style={{ justifyContent: "center" }}
                    className="sort-button"
                    type="button"
                    // onMouseEnter={() => setShowType(true)}
                    // onMouseLeave={() => setShowType(false)}
                    ref={setTypeReferenceElement}
                  >
                    <span className="svg-icon svg-text icon-margin">
                      {getPropertyIcon}
                    </span>
                    <span className="text-transform-capitalize">
                      {dataType}
                    </span>
                  </button>
                </div>
                {/* {options &&  (
                  <div className="list-padding">
                    {options.toString()}
                  </div>
                )} */}
                {/* <div
                  className="list-padding"
                  key={shortId()}
                  style={{
                    borderTop: `2px solid ${grey(200)}`,
                  }}
                >
                  {buttons.map((button) => (
                    <button
                      type="button"
                      className="sort-button"
                      onMouseDown={button.onClick}
                    >
                      <span className="svg-icon svg-text icon-margin">
                        {button.icon}
                      </span>
                      {button.label}
                    </button>
                  ))}
                </div> */}
              </div>
            </div>
          )}
        </>
      );
    }
    return (
      <div {...getHeaderProps()} className="th noselect d-inline-block">
        <div className="th-content" ref={setReferenceElement}>
          {label}
        </div>
        <div {...getResizerProps()} className="resizer" />
      </div>
    );
  }

  useEffect(() => {
    if (created) {
      setExpanded(true);
    }
  }, [created]);

  useEffect(() => {
    setHeader(label);
  }, [label]);

  return getHeader();
}
