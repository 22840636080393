import React, { useState, useEffect, useReducer } from "react";
import update from "immutability-helper";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "../App.css";
import "../style.css";
import { ActionTypes, DataTypes, selectMaker, randomColor } from "../utils";
import Table from "../TableUtils/Table";
import ReactTooltip from 'react-tooltip';
import Collapsible from './Collapsible';

function reducer(state, action) {
  switch (action.type) {
    case ActionTypes.ADD_OPTION_TO_COLUMN:
      const optionIndex = state.columns.findIndex(
        (column) => column.id === action.columnId
      );
      return update(state, {
        skipReset: { $set: true },
        columns: {
          [optionIndex]: {
            options: {
              $push: [
                {
                  label: action.option,
                  backgroundColor: action.backgroundColor,
                },
              ],
            },
          },
        },
      });
    case ActionTypes.ADD_ROW:
      return update(state, {
        skipReset: { $set: true },
        data: { $push: [{}] },
      });
    case ActionTypes.ADD_ROWNEW:
      return update(state, {
        skipReset: { $set: true },
        data: { $push: action.rowd },
      });
    case ActionTypes.UPDATE_COLUMN_TYPE:
      const typeIndex = state.columns.findIndex(
        (column) => column.id === action.columnId
      );
      switch (action.dataType) {
        case DataTypes.NUMBER:
          if (state.columns[typeIndex].dataType === DataTypes.NUMBER) {
            return state;
          } else {
            return update(state, {
              skipReset: { $set: true },
              columns: { [typeIndex]: { dataType: { $set: action.dataType } } },
              data: {
                $apply: (data) =>
                  data.map((row) => ({
                    ...row,
                    [action.columnId]: isNaN(row[action.columnId])
                      ? ""
                      : Number.parseInt(row[action.columnId]),
                  })),
              },
            });
          }
        case DataTypes.SELECT:
          if (state.columns[typeIndex].dataType === DataTypes.SELECT) {
            return state;
          } else if (
            state.columns[typeIndex].dataType === DataTypes.MULTI_SELECT
          ) {
            return update(state, {
              skipReset: { $set: true },
              columns: {
                [typeIndex]: {
                  dataType: { $set: action.dataType },
                  options: {
                    $apply: (options) => {
                      let temp = options;
                      return temp.map((lab) => {
                        return {
                          label: lab,
                          backgroundColor: randomColor(),
                        };
                      });
                    },
                  },
                },
              },
            });
          } else {
            let options = selectMaker(state.data, action.columnId);

            return update(state, {
              skipReset: { $set: true },
              columns: {
                [typeIndex]: {
                  dataType: { $set: action.dataType },
                  options: { $push: options },
                },
              },
            });
          }
        case DataTypes.MULTI_SELECT:
          if (
            state.columns[typeIndex].dataType === DataTypes.MULTI_SELECT ||
            action.columnId === "time signature" ||
            action.columnId === "tempo" ||
            action.columnId === "length(number of bars)"
          ) {
            return state;
          } else {
            return update(state, {
              skipReset: { $set: true },
              columns: {
                [typeIndex]: {
                  dataType: { $set: action.dataType },
                  options: {
                    $apply: (options) => {
                      let temp = options.map((option) => {
                        if (option.label !== "null") {
                          return option.label;
                        }
                      });
                      return temp.toString();
                    },
                  },
                },
              },
            });
          }
        case DataTypes.TEXT:
          if (state.columns[typeIndex].dataType === DataTypes.TEXT) {
            return state;
          } else if (state.columns[typeIndex].dataType === DataTypes.SELECT) {
            return update(state, {
              skipReset: { $set: true },
              columns: { [typeIndex]: { dataType: { $set: action.dataType } } },
            });
          } else if (
            state.columns[typeIndex].dataType === DataTypes.MULTI_SELECT
          ) {
            return update(state, {
              skipReset: { $set: true },
              columns: { [typeIndex]: { dataType: { $set: action.dataType } } },
              data: {
                $apply: (data) =>
                  data.map((row) => ({
                    ...row,
                    [action.columnId]: !(
                      typeof row[action.columnId] === "string" &&
                      row[action.columnId].length > 0
                    )
                      ? row[action.columnId]
                      : "row[action.columnId]",
                  })),
              },
            });
          } else {
            return update(state, {
              skipReset: { $set: true },
              columns: { [typeIndex]: { dataType: { $set: action.dataType } } },
              data: {
                $apply: (data) =>
                  data.map((row) => ({
                    ...row,
                    [action.columnId]: row[action.columnId] + "",
                  })),
              },
            });
          }
        default:
          return state;
      }
    case ActionTypes.UPDATE_CELL:
      return update(state, {
        skipReset: { $set: true },
        data: {
          [action.rowIndex]: { [action.columnId]: { $set: action.value } },
        },
      });
    case ActionTypes.UPDATE_ROW:
      return update(state, {
        skipReset: { $set: true },
        data: {
          [action.index]: { $set: action.payload },
        },
      });
    default:
      return state;
  }
}

const StemInstructions = () => {
  let history = useHistory();
  function buttonredirect() {
    history.push("/submitmusic");
  }
  const columns = [
    {
      id: "no",
      label: " #",
      dataType: DataTypes.STATIC_NUMBER,
      width: 10,
      options: [],
      sticky: "left",
      datatip: "Row number",
      Cell: (row) => {
        return (
          <>
            <div
              className="srno cell-padding d-flex cursor-default align-items-center flex-1"
              style={{ minHeight: "60px", justifyContent: "center" }}
            >
              <div
                className="font-weight-400 d-inline-block border-radius-sm text-transform-capitalize"
                style={{
                  fontSize: "18px",
                  padding: "5px 5px",
                  fontFamily: "inherit",
                  whiteSpace: "break-spaces",
                  // color: rowValid ? "red" : "black",
                }}
              >
                {row.row.index + 1}
              </div>
            </div>
          </>
        );
      },
    },
    {
      id: "filename",
      label: "File Name*",
      accessor: "filename",
      minWidth: 300,
      dataType: DataTypes.STATIC,
      sticky: "left",
      options: [],
      datatip: "Filename of your wav file",
    },
    {
      id: "audio type",
      label: "Audio Type*",
      accessor: "audio type",
      minWidth: 100,
      dataType: DataTypes.SELECT,
      options: selectMaker(
        [
          "loops",
          "stem",
          "sfx",
          "one shot",
          "mixdown",
          "master",
        ].map((tag) => {
          return { "audio type": tag };
        }),
        "audio type"
      ),
      datatip: `<div style="width: 20em">
        <ul>
          <li>loops: audio that loops</li>
          <li>one shot: one shot chords or other non-looping samples</li>
          <li>sfx: sfx like risers or impacts</li>
        </ul>
      </div>`,
    },
    {
      id: "lflag",
      label: "Status",
      accessor: "lflag",
      minWidth: 130,
      dataType: DataTypes.STATIC,
      datatip: `<div style="width: 20em">
      <ul>
        <li>This column shows if your audio file loops perfectly or if it might need trimming.</li>
        <li>If the cell says <span style="background-color: black; font-weight: bold">Needs trim, Did you mean XYZ BPM for A bars?</span>, it means that the tempo you mentioned might not be right for a whole number of bars (1 or 2 or 4 or 8 bars for example).</li>
        <li>It also shows the calculation that was done to figure out the number of bars, given the length in seconds of the audio file and the tempo indicated in the filename.</li>
        <li>No. of beats = (BPM * length)/60s</li>
        <li>No. of bars = (No. of beats)/(4 beats per bar)</li>
      </ul>
    </div>`,
    },
    {
      id: "instrument role",
      label: "Instrument Role*",
      accessor: "instrument role",
      minWidth: 250,
      dataType: DataTypes.MULTI_SELECT,
      datatip: `<div style="width: 20em">
      What general role would this loop play in a track?
      <ul>
        <li>Bass: Synth Basslines, 808s, Bass guitars etc</li>
        <li>Chords: Chord loops, Pad loops, something that would provide a foundation for a track</li>
        <li>Melody: A lead synth melody, a piano melody, etc </li>
        <li>Percussion: full drum loops, top loops, other percussion loops like tabla etc</li>
        <li>SFX: Transitions, Fills, or other SFX like risers and impacts</li>
      </ul>
    </div>`,
      options: [],
    },
    {
      id: "instrument",
      label: "Instrument*",
      accessor: "instrument",
      minWidth: 100,
      dataType: DataTypes.MULTI_SELECT,
      datatip: `<div style="width: 20em">
      What instruments were used in the loop?<br />If you cannot find the exact instrument please select the instrument that sounds closest to the one you used.<br />For example, if you used tabla, select percussion. If you used a saxophone, select brass, and so on.
      </div>`,
      options: [],
    },
    {
      id: "mood",
      label: "Moods* (multiple)",
      accessor: "mood",
      minWidth: 150,
      dataType: DataTypes.MULTI_SELECT,
      datatip: `<div style="width: 20em">
      What is the mood of the loop?
      </div>`,
      options: [],
    },
    {
      id: "key",
      label: "Key*",
      accessor: "key",
      minWidth: 100,
      dataType: DataTypes.MULTI_SELECT,
      datatip: `<div style="width: 20em">
      What is the key of the loop? <br/>
      Options are shown in sharp notation, for example: A# instead of Bb <br/>
      A key without any "m" ex: "a" indicates that the loop is in a major key, i.e "A major" for the example. <br/> A key with an "m" after it ex: "am" indicates that the loop is in a minor key, i.e "A minor" for the example. <br/>
      If there is no specific key (ex for untuned drums or SFX), please select "atonal".
      </div>`,
      options: []
    },

    {
      id: "genre",
      label: "Genre*",
      accessor: "genre",
      minWidth: 200,
      dataType: DataTypes.MULTI_SELECT,
      options: [],
    },
    {
      id: "recommended section",
      label: "Recommended Section*",
      accessor: "recommended section",
      minWidth: 250,
      dataType: DataTypes.MULTI_SELECT,
      datatip: `<div style= "width: 40em !important" >
        What section of the track would this loop be best suited in?
        <ul>
          <li>Intro: loop would suit the first few bars of the track</li>
          <li>Verse: loop would suit the verse, the part that contains the details of a track: the story, the events, and the moods.</li>
          <li>buildup, progression: loop would suit the buildup to a chorus or a drop</li>
          <li>Chorus, drop: loop would suit the main part of the track, the chorus, or the drop.</li> 
          <li>Break, bridge: loop would suit a part after the chorus</li>
          <li>Outro: The last few bars of the track</li>
          <li>Percussion: Loop is a percussion loop and will suit most sections of a track</li>
          <li>Ambience: SFX, ambience, vinyl noise, foley etc</li>
          <li>Transitions, SFX: One shots like drum fills, risers and impacts</li>
        </ul>
      </div>`,
      options: [],
    },
    {
      id: "tempo",
      label: "Tempo (BPM)*",
      accessor: "tempo",
      minWidth: 100,
      dataType: DataTypes.NUMBER,
      datatip: `<div style="width: 20em">
      What is the tempo of the loop in BPM?
      </div>`,
      options: [],
    },
    {
      id: "length(number of bars)",
      label: "Length (bars)*",
      accessor: "length(number of bars)",
      minWidth: 100,
      dataType: DataTypes.NUMBER,
      options: [],
      datatip: `<div style="width: 20em">
      Length of the loop in bars. If the length of the loop is missing, either replace the sample with a file with the tempo mentioned in the filename or enter the length in bars directly.
      </div>`
    },

    {
      id: "time signature",
      label: "Time Signature",
      accessor: "time signature",
      minWidth: 100,
      dataType: DataTypes.TEXT,
      datatip: `<div style="width: 20em">
      What is the time signature of the loop? 4/4 or 3/4?
      </div>`,
      options: [],
    },
    {
      id: "frequency range",
      label: "Frequency Range*",
      accessor: "frequency range",
      minWidth: 200,
      dataType: DataTypes.SELECT,
      datatip: `<div style="width: 20em">
      What is the frequency range of the loop? This is automatically calculated during upload but you can change it if you believe it is incorrect.
      <ul>
      <li> Low: Average frequency range is lower than 500Hz</li>
      <li> Mid: Average frequency range is between 500Hz and 5000Hz</li>
      <li> High: Average frequency range is higher than 5000Hz</li>
      </ul>
      </div>`,
      options: selectMaker(
        ["low", "mid", "high"].map((tag) => {
          return { "frequency range": tag };
        }),
        "frequency range"
      ),
    },

    {
      id: "scale",
      label: "Scale",
      accessor: "scale",
      minWidth: 100,
      dataType: DataTypes.TEXT,
      datatip: `<div style="width: 20em">
      What scale is the loop in?
      </div>`,
      options: [],
    },
  ];
  const data1 = [
    {
      filename: "Piano Chords/sad-130-Db-Indie ballad.wav",
      "audio type": "loops",
      "instrument role": "chords",
      instrument: "piano",
      "length(number of bars)": 4,
      "frequency range": "mid",
      tempo: 130,
      key: "c#",
      "time signature": "4/4",
      genre: "indie",
      mood: "sad",
      "if any other mood, please mention here": "",
      "if any other mood, please mention here": "",
      moodintensity: "",
      region: "western",
      music_theory: "western",
      scale: "",
      "recommended section": "verse",
      activity_theme: "",
      lflag: "Loops Perfectly",
    },
  ];
  const data2 = [
    {
      "filename": "sad-65-Em-Ambient/bass/guitar-v3.wav",
      "audio type": "stem",
      "instrument role": "bass",
      "instrument": "guitar",
      "length(number of bars)": 96,
      "frequency range": "mid",
      "tempo": 65,
      "key": "em",
      "time signature": "4/4",
      "genre": "ambient",
      "mood": "sad",
      "if any other mood, please mention here": "",
      "mood intensity(1 - lowest, 5 - highest)": "",
      "region": "western",
      "music_theory": "western",
      "scale": "",
      "recommended section": "Part C",
      "activity_theme": "A Sad Ambient Track",
      "lflag": "Loops Perfectly"
    },
    {
      "filename": "sad-65-Em-Ambient/bass/synth-v1.mid",
      "audio type": "stem",
      "instrument role": "bass",
      "instrument": "synth",
      "length(number of bars)": 8,
      "frequency range": "mid",
      "tempo": 65,
      "key": "em",
      "time signature": "4/4",
      "genre": "ambient",
      "mood": "sad",
      "if any other mood, please mention here": "",
      "mood intensity(1 - lowest, 5 - highest)": "",
      "region": "western",
      "music_theory": "western",
      "scale": "",
      "recommended section": "Part A",
      "activity_theme": "A Sad Ambient Track",
      "lflag": "Loops Perfectly"
    },
    {
      "filename": "sad-65-Em-Ambient/bass/synth-v1.wav",
      "audio type": "stem",
      "instrument role": "bass",
      "instrument": "synth",
      "length(number of bars)": 96,
      "frequency range": "mid",
      "tempo": 65,
      "key": "em",
      "time signature": "4/4",
      "genre": "ambient",
      "mood": "sad",
      "if any other mood, please mention here": "",
      "mood intensity(1 - lowest, 5 - highest)": "",
      "region": "western",
      "music_theory": "western",
      "scale": "",
      "recommended section": "Part A",
      "activity_theme": "A Sad Ambient Track",
      "lflag": "Loops Perfectly"
    },
    {
      "filename": "sad-65-Em-Ambient/chords/piano-v1.mid",
      "audio type": "stem",
      "instrument role": "chords",
      "instrument": "piano",
      "length(number of bars)": 8,
      "frequency range": "mid",
      "tempo": 65,
      "key": "em",
      "time signature": "4/4",
      "genre": "ambient",
      "mood": "sad",
      "if any other mood, please mention here": "",
      "mood intensity(1 - lowest, 5 - highest)": "",
      "region": "western",
      "music_theory": "western",
      "scale": "",
      "recommended section": "Part A",
      "activity_theme": "A Sad Ambient Track",
      "lflag": "Loops Perfectly"
    },
    {
      "filename": "sad-65-Em-Ambient/chords/piano-v1.wav",
      "audio type": "stem",
      "instrument role": "chords",
      "instrument": "piano",
      "length(number of bars)": 96,
      "frequency range": "mid",
      "tempo": 65,
      "key": "em",
      "time signature": "4/4",
      "genre": "ambient",
      "mood": "sad",
      "if any other mood, please mention here": "",
      "mood intensity(1 - lowest, 5 - highest)": "",
      "region": "western",
      "music_theory": "western",
      "scale": "",
      "recommended section": "Part A",
      "activity_theme": "A Sad Ambient Track",
      "lflag": "Loops Perfectly"
    },
    {
      "filename": "sad-65-Em-Ambient/chords/piano-v2.wav",
      "audio type": "stem",
      "instrument role": "chords",
      "instrument": "piano",
      "length(number of bars)": 96,
      "frequency range": "mid",
      "tempo": 65,
      "key": "em",
      "time signature": "4/4",
      "genre": "ambient",
      "mood": "sad",
      "if any other mood, please mention here": "",
      "mood intensity(1 - lowest, 5 - highest)": "",
      "region": "western",
      "music_theory": "western",
      "scale": "",
      "recommended section": "Part B",
      "activity_theme": "A Sad Ambient Track",
      "lflag": "Loops Perfectly"
    },
    {
      "filename": "sad-65-Em-Ambient/fx/foley-v3.wav",
      "audio type": "sfx",
      "instrument role": "sfx",
      "instrument": "sfx",
      "length(number of bars)": 96,
      "frequency range": "mid",
      "tempo": 65,
      "key": "atonal",
      "time signature": "4/4",
      "genre": "ambient",
      "mood": "experimental,foley",
      "if any other mood, please mention here": "",
      "mood intensity(1 - lowest, 5 - highest)": "",
      "region": "western",
      "music_theory": "western",
      "scale": "",
      "recommended section": "Part C",
      "activity_theme": "A Sad Ambient Track",
      "lflag": "Loops Perfectly"
    },
    {
      "filename": "sad-65-Em-Ambient/fx/riser-v1.wav",
      "audio type": "sfx",
      "instrument role": "sfx",
      "instrument": "sfx",
      "length(number of bars)": 96,
      "frequency range": "mid",
      "tempo": 65,
      "key": "atonal",
      "time signature": "4/4",
      "genre": "ambient",
      "mood": "sad",
      "if any other mood, please mention here": "",
      "mood intensity(1 - lowest, 5 - highest)": "",
      "region": "western",
      "music_theory": "western",
      "scale": "",
      "recommended section": "Part A",
      "activity_theme": "A Sad Ambient Track",
      "lflag": "Loops Perfectly"
    },
    {
      "filename": "sad-65-Em-Ambient/fx/whitenoise-v1.wav",
      "audio type": "sfx",
      "instrument role": "sfx",
      "instrument": "sfx",
      "length(number of bars)": 96,
      "frequency range": "mid",
      "tempo": 65,
      "key": "atonal",
      "time signature": "4/4",
      "genre": "ambient",
      "mood": "sad",
      "if any other mood, please mention here": "",
      "mood intensity(1 - lowest, 5 - highest)": "",
      "region": "western",
      "music_theory": "western",
      "scale": "",
      "recommended section": "Part A",
      "activity_theme": "A Sad Ambient Track",
      "lflag": "Loops Perfectly"
    },
    {
      "filename": "sad-65-Em-Ambient/melody/guitar-v1.mid",
      "audio type": "stem",
      "instrument role": "melody",
      "instrument": "guitar",
      "length(number of bars)": 8,
      "frequency range": "mid",
      "tempo": 65,
      "key": "em",
      "time signature": "4/4",
      "genre": "ambient",
      "mood": "sad",
      "if any other mood, please mention here": "",
      "mood intensity(1 - lowest, 5 - highest)": "",
      "region": "western",
      "music_theory": "western",
      "scale": "",
      "recommended section": "Part A",
      "activity_theme": "A Sad Ambient Track",
      "lflag": "Loops Perfectly"
    },
    {
      "filename": "sad-65-Em-Ambient/melody/guitar-v1.wav",
      "audio type": "stem",
      "instrument role": "melody",
      "instrument": "guitar",
      "length(number of bars)": 96,
      "frequency range": "mid",
      "tempo": 65,
      "key": "em",
      "time signature": "4/4",
      "genre": "ambient",
      "mood": "sad",
      "if any other mood, please mention here": "",
      "mood intensity(1 - lowest, 5 - highest)": "",
      "region": "western",
      "music_theory": "western",
      "scale": "",
      "recommended section": "Part A",
      "activity_theme": "A Sad Ambient Track",
      "lflag": "Loops Perfectly"
    },
    {
      "filename": "sad-65-Em-Ambient/melody/synth-v3.wav",
      "audio type": "stem",
      "instrument role": "melody",
      "instrument": "synth",
      "length(number of bars)": 96,
      "frequency range": "mid",
      "tempo": 65,
      "key": "em",
      "time signature": "4/4",
      "genre": "ambient",
      "mood": "sad",
      "if any other mood, please mention here": "",
      "mood intensity(1 - lowest, 5 - highest)": "",
      "region": "western",
      "music_theory": "western",
      "scale": "",
      "recommended section": "Part C",
      "activity_theme": "A Sad Ambient Track",
      "lflag": "Loops Perfectly"
    },
    {
      "filename": "sad-65-Em-Ambient/percussion/drumfill-v1.wav",
      "audio type": "stem",
      "instrument role": "percussion",
      "instrument": "percussion",
      "length(number of bars)": 96,
      "frequency range": "mid",
      "tempo": 65,
      "key": "atonal",
      "time signature": "4/4",
      "genre": "ambient",
      "mood": "sad",
      "if any other mood, please mention here": "",
      "mood intensity(1 - lowest, 5 - highest)": "",
      "region": "western",
      "music_theory": "western",
      "scale": "",
      "recommended section": "Part A",
      "activity_theme": "A Sad Ambient Track",
      "lflag": "Loops Perfectly"
    },
    {
      "filename": "sad-65-Em-Ambient/percussion/drums-v1.mid",
      "audio type": "stem",
      "instrument role": "percussion",
      "instrument": "percussion",
      "length(number of bars)": 8,
      "frequency range": "mid",
      "tempo": 65,
      "key": "atonal",
      "time signature": "4/4",
      "genre": "ambient",
      "mood": "sad",
      "if any other mood, please mention here": "",
      "mood intensity(1 - lowest, 5 - highest)": "",
      "region": "western",
      "music_theory": "western",
      "scale": "",
      "recommended section": "Part A",
      "activity_theme": "A Sad Ambient Track",
      "lflag": "Loops Perfectly"
    },
    {
      "filename": "sad-65-Em-Ambient/percussion/drums-v1.wav",
      "audio type": "stem",
      "instrument role": "percussion",
      "instrument": "percussion",
      "length(number of bars)": 96,
      "frequency range": "mid",
      "tempo": 65,
      "key": "atonal",
      "time signature": "4/4",
      "genre": "ambient",
      "mood": "sad",
      "if any other mood, please mention here": "",
      "mood intensity(1 - lowest, 5 - highest)": "",
      "region": "western",
      "music_theory": "western",
      "scale": "",
      "recommended section": "Part A",
      "activity_theme": "A Sad Ambient Track",
      "lflag": "Loops Perfectly"
    },
    {
      "filename": "sad-65-Em-Ambient/percussion/tambourine-v3.wav",
      "audio type": "stem",
      "instrument role": "percussion",
      "instrument": "percussion",
      "length(number of bars)": 96,
      "frequency range": "mid",
      "tempo": 65,
      "key": "atonal",
      "time signature": "4/4",
      "genre": "ambient",
      "mood": "sad",
      "if any other mood, please mention here": "",
      "mood intensity(1 - lowest, 5 - highest)": "",
      "region": "western",
      "music_theory": "western",
      "scale": "",
      "recommended section": "Part C",
      "activity_theme": "A Sad Ambient Track",
      "lflag": "Loops Perfectly"
    }
  ];
  const [grid1, setGrid1] = useState(data1);
  const [grid2, setGrid2] = useState(data2);

  const [state1, dispatch] = useReducer(reducer, {
    columns: columns,
    data: grid1,
    skipReset: true,
  });

  const [state2, dispatch1] = useReducer(reducer, {
    columns: columns,
    data: grid2,
    skipReset: true,
  });

  useEffect(() => {
    setGrid1(state1.data);
    setGrid2(state2.data);
  }, [state1.data, state2.data, state1, state2]);

  return (
    <>
      <div className="">
        <ReactTooltip className="tooltip" />
        <Collapsible title="Submission Guidelines for submitting stems">
          <div className="">
            {/* <h1>Submission Guidelines for submitting stems</h1> */}
            <div className="imp">Export your stems with these settings: </div>

            <div className="imp">
              <ul>
                <li>
                  Minimum Length: <b>2:30</b>
                </li>
                <li>
                  File Format: <b>.wav or .mid</b>
                </li>
                <li>
                  True Peak: <b>-3db</b>{" "}
                </li>
                <li>
                  Sample Rate: <b>44100 Hz</b>
                </li>
                <li>
                  Bitrate: Recommended - <b>32bit - 24bit</b>, Minimum - <b>16bit</b>{" "}
                </li>
              </ul>
            </div>

            <div className="imp">
              Make sure you've tagged your stems with the right BPM and
              that they align with the beatgrid correctly.
            </div>

            <div className="imp">
              Collect your exports, zip them and upload. <u>Only submit one track per zip file.</u>{" "}
            </div>

            <div className="imp">
              This webapp will autofill any tags that you mention in your file
              name or in the folder names. Any tags that are not autofilled can be filled manually using the tag input fields.
            </div>
            <h1>Composition Guidelines</h1>
            <h2>Complete compositions (i.e., full music, start to finish)</h2>
            <p><ul>
              <li>Minimum Duration: 2:30 minutes</li>
            </ul></p><h2>Global annotations</h2>
            <p><ul>
              <li>Genre</li>
              <li>Mood</li>
              <li>Tempo</li>
              <li>Key/scale</li>
              <li>Theme (optional)</li>
            </ul></p><h2>Individual audio stems</h2>
            <p><ul>
              <li>Stems exported beginning to end of song including any silence, where each stem the same length</li>
            </ul></p><h2>Textual description of a song</h2>
            <p><ul>
              <li>To describe what it can be used for e.g. ‘A psytrance track with very high energy which can be used for a party scene in a movie, a fighting scene in a movie or a fast-paced movie with scenes of nature’.</li>
            </ul></p><h2>MIDI files</h2>
            <p><ul>
              <li>Exported from the same project as the audio stems</li>
            </ul></p><h2>3+ Instrumentation roles</h2>
            <p><ul>
              <li>Melody</li>
              <li>Chords</li>
              <li>Fx (inc. textures, field recordings, foley etc.?)</li>
              <li>Bass</li>
              <li>Percussion</li>
              <li>Genre specifics (e.g., ambient and cinematic may not have percussion)</li>
            </ul></p><h2>2+ Variations per role</h2>
            <p><ul>
              <li>A variation may be:</li>
              <ul>
                <li>Change in instrument</li>
                <li>An additional layer</li>
                <li>Change in rhythmical pattern</li>
                <li>Change in melodic pattern</li>
                <li>Change in chord progression (e.g.,  I-V-vi-IV to I, IV and V)</li>
                <li>Fills (e.g., drum fill, melody turn over, chord change) [x]</li>
                <li>transitional effects (e.g., noise sweeps, synths risers)</li>
                <li>Layering effects (e.g., reverb, delay)</li>
              </ul>
              <li>Each variation of a role (e.g., melody) such be exported as a separate stem and labelled/annotated as variation A, B, C, etc. in the filename</li>
              <li>We will ask on the upload page for the artist to tag the variations (if it is a variation within a section (e.g. a fill) or a variation to another section (e.g. different melody in the verse and the chorus)</li>
            </ul></p><h2>5+ Instruments (preferably more)</h2>
            <p><ul>
              <li>For example: Piano, Guitar, Synth, Double Bass, White noise fx, drums, tambourines, shakers</li>
              <li>Each instrument should be exported to its own separate stem and labelled by its instrument type and instrumentation role (e.g., melody guitar, melody synth, chords piano, chords guitar, percussion drums, percussion shaker)</li>
            </ul></p><h2>Mixdown and Master</h2>
            <p><ul>
              <li>Provide a single audio mixdown file</li>
              <li>Provide a single audio mastered file</li>
              <li>All files should be 16-bit, 44k1Hz wav files</li>
            </ul></p><h2>Provide annotation of musical structure</h2>
            <p><ul>
              <li>Phrases: e.g., introduction, Chorus, verse, bridge</li>
              <li>This should be a separate file with start-end time locations for each phrase, see section #3 below</li>
            </ul>
            </p>


            <h1>Tagging and organising your stems</h1>
            <h2>1. File Names</h2>
            <p>
              Lets say you've written a 16 bar piano melody, in D Major, at 130
              BPM. You describe its mood as dreamy or calm. You could name your
              sample like this:
              <b>
                <h5 className="sample">"piano_melody_130_D_calm.wav"</h5>
              </b>
              <br />
              The order of these tags: "piano", "melody", "130", "D", "calm" does
              not matter,
              <b>
                {" "}
                as long as you mention these tags somewhere, separated by a space " ", comma
                ",", a hypen "-", or an underscore "_", they will get autofilled.
              </b>
            </p>
            <h3>Autofilled tags for "piano_melody_130_D_calm.wav"</h3>
            <p>
              Go through each of these fields. Select menu fields are initialised
              with a few default values.
            </p>

            <div
              style={{ borderWidth: "1px", width: "100%" }}
              className="react-table-container"
            >
              <div className="overflow-y-hidden" style={{ marginBlock: "1%" }}>
                <Table
                  columns={columns}
                  data={grid1}
                  dispatch={dispatch}
                  skipReset={state1.skipReset}
                />
                <div id="popper-portal"></div>
              </div>
            </div>
            <h2>2. Folder Structure</h2>
            <p>
              If you have enough loops that share a common tag, put them in a
              folder, and give each sample a unique name, for example:
            </p>
            <div
              style={{
                lineHeight: "1.2", color: "white", fontSize: "1.25rem", paddingLeft: "0.5rem", fontFamily: "monospace"

              }}
            >
              indie-song.zip<br /><br />
              ├──&nbsp;sad-65-Em-Ambient<br />
              │&nbsp;&nbsp;&nbsp;├──&nbsp;bass<br />
              │&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;├──&nbsp;guitar-v3.wav<br />
              │&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;├──&nbsp;synth-v1.mid<br />
              │&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;└──&nbsp;synth-v1.wav<br />
              │&nbsp;&nbsp;&nbsp;├──&nbsp;chords<br />
              │&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;├──&nbsp;piano-v1.mid<br />
              │&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;├──&nbsp;piano-v1.wav<br />
              │&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;└──&nbsp;piano-v2.wav<br />
              │&nbsp;&nbsp;&nbsp;├──&nbsp;fx<br />
              │&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;├──&nbsp;foley-v3.wav<br />
              │&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;├──&nbsp;riser-v1.wav<br />
              │&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;└──&nbsp;whitenoise-v1.wav<br />
              │&nbsp;&nbsp;&nbsp;├──&nbsp;melody<br />
              │&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;├──&nbsp;guitar-v1.mid<br />
              │&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;├──&nbsp;guitar-v1.wav<br />
              │&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;├──&nbsp;synth-v3.mid&nbsp;&nbsp;&nbsp;&nbsp;<br />
              │&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;└──&nbsp;synth-v3.wav<br />
              │&nbsp;&nbsp;&nbsp;└──&nbsp;percussion<br />
              │&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;├──&nbsp;drumfill-v1.wav<br />
              │&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;├──&nbsp;drums-v1.mid<br />
              │&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;├──&nbsp;drums-v1.wav<br />
              │&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;└──&nbsp;tambourine-v3.wav<br />


            </div>
            <h3>Autofilled tags for "indie-song.zip". Click and scroll to interact with the table.</h3>
            <p>
              Note that tags mentioned in the folder names also get autofilled.
              This will help you organise your samples.
            </p>
            <div
              style={{ borderWidth: "1px", width: "100%" }}
              className="react-table-container"
            >
              <div className="overflow-y-hidden" style={{ marginBlock: "1%" }}>
                <Table
                  columns={columns}
                  data={grid2}
                  dispatch={dispatch1}
                  skipReset={state2.skipReset}
                />
                <div id="popper-portal"></div>
              </div>
            </div>

            <h2>3. Structure information</h2>
            <p>Provide a .txt file with the overall structure/form of the track
              <ul>
                <li>
                  This represents how the music phrases (e.g., intro, chorus, verse) change over time
                </li>
                <li>
                  Name of phrase and the number of bars it occurs for
                </li>
                <li>
                  Separate each phrase on to a new line
                </li>
                <li>
                  Use hyphen between phrase name and number of bars
                  Example: <a
                    href="https://drive.google.com/file/d/1hBOBsWqGP8hlckMYxCtwwhrmEdz-0NLq/view?usp=drive_link"
                  >structure.txt</a>
                </li>
              </ul>
            </p>

            <div
              style={{
                lineHeight: "1.2", color: "white", fontSize: "1.25rem", paddingLeft: "0.5rem", fontFamily: "monospace"

              }}
            >
              structure.txt<br /><br />
              intro-8<br />
              verse-8<br />
              chorus-16<br />
              verse-8<br />
              bridge-8<br />
              chorus-16<br />
              verse-8<br />
              chorus-16<br />
              outro-8<br />
            </div>


            <div className="container">
              <h1>Got It? Submit your music</h1>
              <Button
                className="submitmusic"
                variant="primary"
                onClick={buttonredirect}
                size="lg"
                style={{ marginBottom: "80px" }}
              >
                Submit your music
              </Button>
            </div>
          </div>
        </Collapsible>
      </div>
    </>
  );
};

export default StemInstructions;
