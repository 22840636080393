import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import "../App.css";

function Thankyou() {
  let history = useHistory();

  const buttonredirect = () => {
    history.push("/instructions");
  };
  return (
    <div>
      <div className="mcontainer" style={{height:"80vh", marginTop:"0px"}}>
        <h1
          className="title"
          style={{ fontFamily: "Poppins", fontSize: "72px", fontWeight: "500" }}
        >
          thank you
        </h1>
        <h2
          className="title"
          style={{ fontFamily: "Poppins", fontSize: "36px", fontWeight: "500", lineHeight: "1.5" }}
        >
          we will reach out to you soon, <br />
          have any questions? mail us here:
          <br />
          <a href="mailto: community@beatoven.ai?subject=About my submission&cc=syedali@beatoven.ai">community@beatoven.ai </a>
        </h2>
        <Button
              className="submitmusic"
              variant="primary"
              onClick={buttonredirect}
              size="lg"
              style={{ margin: "20px" }}
            >
              submit more music
            </Button>
      </div>
    </div>
  );
}

export default Thankyou;
