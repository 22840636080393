import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import "../App.css";
// import Footer from "../Components/Footer";
import { useAuth0 } from "@auth0/auth0-react";
import CombinedInstructions from "./CombinedInstructions";

function Home() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  let history = useHistory();

  const buttonredirect = () => {
    history.push("/instructions");
  };

  return (
    <>
      {!isAuthenticated && (
        <div>
          <div className="mcontainer hometext">
            <h1 className="title">Music Submission Channel For Artists</h1>
          </div>
          <div className="btns">
            <Button
              className="submitmusic"
              variant="primary"
              onClick={loginWithRedirect}
              size="lg"
              style={{ margin: "20px" }}
            >
              Be a guru for Beatoven.ai
            </Button>
          </div>
        </div>
      )}
      {isAuthenticated && (
        <div>
          <div className="mcontainer2 hometext">
            <h1 className="title">Music submission channel for artists</h1>
          </div>
          <CombinedInstructions />
        </div>
      )}
    </>
  );
}

export default Home;
