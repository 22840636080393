import React from "react";
import clsx from "clsx";
import { useTable, useBlockLayout, useResizeColumns, usePagination} from "react-table";
import Cell from "./Cell";
import Header from "./Header";
import "../App.css";
import "../style.css";
import { useSticky } from "react-table-sticky";

const defaultColumn = {
  minWidth: 50,
  width: 150,
  maxWidth: 400,
  Cell: Cell,
  Header: Header,
  sortType: "alphanumericFalsyLast",
};

export default function Table({
  columns,
  data,
  dispatch: dataDispatch,
  skipReset,
}) {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    page,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      dataDispatch,
      autoResetRowState: !skipReset,
      initialState: { pageIndex: 0, pageSize: 16 },
    },
    useBlockLayout,
    useResizeColumns,
    useSticky,
    usePagination,
  );

  const sty = (index) => {
    return {
      display: "flex",
      minHeight: "60",
      left: 0,
      right: undefined,
      top: index * 60,
    };
  };

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div {...row.getRowProps({ style })} className="tr">
          {row.cells.map((cell) => {
            return (
              <div {...cell.getCellProps()} className="td">
                {cell.render("Cell")}
              </div>
            );
          })}
        </div>
      );
    },
    [prepareRow, rows]
  );

  function isTableResizing() {
    for (let headerGroup of headerGroups) {
      for (let column of headerGroup.headers) {
        if (column.isResizing) {
          return true;
        }
      }
    }

    return false;
  }

  return (
    <>
      <div className="overflow-y-auto">
        <div
          {...getTableProps()}
          className={"new" || clsx("table", isTableResizing() && "noselect")}
        >
          <div>
            <div>
              {headerGroups.map((headerGroup) => (
                <div
                  {...headerGroup.getHeaderGroupProps({ border: "none" })}
                  className="hdr"
                >
                  {headerGroup.headers.map((column) => column.render("Header"))}
                </div>
              ))}
            </div>
            {page.map((i) => (
              <RenderRow index={i.index} style={sty(i.index)} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
