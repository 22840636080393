import React, { useEffect, useState } from "react";
import "../App.css";
import { Button } from "react-bootstrap/";
import { useAuth0 } from "@auth0/auth0-react";
import http from "../http-commons";
import { API, DataTypes } from "../utils";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import ProfileTable from "../TableUtils/profiletable";
import DeleteIcon from "../img/delete";
const fetchData = async (user) => {
  const response = await http.get(API.GET_USER_INFO + user.email);
  return response.data;
};

const Profile = () => {
  const { user } = useAuth0();
  const [userData, setUserData] = useState();
  const [totalLoopCount, settotalLoopCount] = useState(0);
  let history = useHistory();
  useEffect(() => {
    fetchData(user)
      .then(async (res) => {
        setUserData(res.submissions.reverse());
        // settotalLoopCount(res.totalLoopCount);
        if ("total_loop_count" in res) {
          settotalLoopCount(res.total_loop_count);
        } else {
          settotalLoopCount("N/A");
        }
      })
      .catch((err) => {
        console.log(err);
        window.alert("Error user fetching data, contact us if this persists");
      });
  }, []);

  const deleteSubmission = async (id, submission_name) => {
    let submissionRequest = {
      submission_id: id,
      email: user.email,
      submission_name: submission_name,
    };
    if (!window.confirm("Are you sure you want to delete this submission?")) {
      return
    }
    let response = await http
      .post(API.DELETE_SUBMISSION, submissionRequest, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .catch((err) => {
        console.log(err);
        window.alert("Error: " + err + " contact us if this persists");
      });
    fetchData(user)
      .then(async (res) => {
        setUserData(res.submissions.reverse());
        if ("total_loop_count" in res) {
          settotalLoopCount(res.total_loop_count);
        } else {
          settotalLoopCount("N/A");
        }
      })
      .catch((err) => {
        console.log(err);
        window.alert("Error user fetching data, contact us if this persists");
      });
  };

  const getCollectedInfo = async (submission_id, submission_name) => {
    let submissionRequest = {
      submission_id: submission_id,
      email: user.email,
      submission_name: submission_name,
    };
    let response = await http
      .post(API.GET_CSV_CHECKPOINT, submissionRequest, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .catch((err) => {
        console.log(err);
        window.alert("Error: " + err + " contact us if this persists");
      });

    const savedState = localStorage.getItem(submission_id);
    if (!savedState) {
      localStorage.setItem(
        submission_id,
        JSON.stringify({
          df: JSON.parse(response.data.df),
          tl: response.data.tl,
          submissionId: submission_id,
          defaults: response.data.defaults,
          error_messages: response.data.error_messages,
        })
      );
    } else {
      // check if error messages are in the saved state
      let savedStateJson = JSON.parse(savedState);
      if (!("error_messages" in savedStateJson)) {
        savedStateJson.error_messages = response.data.error_messages;
        localStorage.setItem(submission_id, JSON.stringify(savedStateJson));
      }
    }

    history.push("/submitmusic/collectedinfo", {
      df: response.data.df,
      tl: response.data.tl,
      submissionId: submission_id,
      submissionDefaults: response.data.defaults,
      error_messages: response.data.error_messages,
    });
  };

  const columns = React.useMemo(
    () => [
      {
        id: 999999,
        minWidth: 150,
        dataType: DataTypes.STATIC,
        label: "Submission Name",
        Cell: ({ row }) => {
          if (row.original.total_length === 0) {
            return (
              <div
                className="data-input cell-padding d-flex cursor-default align-items-center flex-1"
                style={{ minHeight: "60px", color: "red", display: "flex" }}
              >
                {row.original.submission_name}
              </div>
            );
          } else if (row.original.submission_status === "incomplete") {
            return (
              <div
                className="data-input cell-padding d-flex cursor-default align-items-center flex-1"
                style={{ minHeight: "60px", color: "#31f080", display: "flex" }}
              >
                {row.original.submission_name}
              </div>
            );
          } else {
            return (
              <div
                className="data-input cell-padding d-flex cursor-default align-items-center flex-1"
                style={{ minHeight: "60px", display: "flex" }}
              >
                {row.original.submission_name}
              </div>
            );
          }
        },
      },
      {
        id: 9997999,
        minWidth: 150,
        dataType: DataTypes.STATIC,
        label: "Total Length",
        Cell: ({ row }) => {
          if (row.original.total_length === 0) {
            return (
              <div
                className="data-input cell-padding d-flex cursor-default align-items-center flex-1"
                style={{ minHeight: "60px", color: "red", display: "flex" }}
              >
                {("loop_count" in row.original ? row.original.loop_count + " loops, " : "") + "unsaved submission"}
              </div>
            );
          } else {
            return (
              <div
                className="data-input cell-padding d-flex cursor-default align-items-center flex-1"
                style={{ minHeight: "60px", display: "flex" }}
              >
                {("loop_count" in row.original ? row.original.loop_count + " loops, " : "") + + row.original.total_length.toFixed(2) + "s"}
              </div>
            );
          }
        },
      },
      {
        id: 999998,
        minWidth: 150,
        dataType: DataTypes.STATIC_NUMBER,
        label: "File Size",
        Cell: ({ row }) => {
          return (
            <div
              className="data-input text-align-right cell-padding d-flex cursor-default align-items-center flex-1"
              style={{ minHeight: "60px", display: "flex" }}
            >
              {(row.original.filesize / 1024 / 1024).toFixed(2) + " MB"}
            </div>
          );
        },
      },
      {
        id: 999997,
        minWidth: 200,
        dataType: DataTypes.STATIC,
        label: "Date Created",
        // accessor: "date_created",
        Cell: ({ row }) => {
          let r = moment.utc(row.original.date_created, "DD-MM-YYYY HH:mm:ss");
          // console.log(
          //   r.tz("Asia/Kolkata").format("dddd, Do MMMM YYYY, h:mm:ss a")
          // );
          return (
            <div
              className="data-input cell-padding d-flex cursor-default align-items-center flex-1"
              style={{ minHeight: "60px", display: "flex" }}
            >
              {r.format("dddd, Do MMM YYYY, h:mm:ss a")}
            </div>
          );
        },
      },
      {
        id: 999996,
        minWidth: 250,
        dataType: DataTypes.STATIC,
        label: "Status",
        Cell: ({ row }) => {
          if (
            row.original.submission_status === "incomplete" ||
            row.original.csv_location.substring(0, 5) === "/app/"
          ) {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    whiteSpace: "initial",
                    textAlign: "-webkit-left",
                    padding: "10px",
                  }}>
                  {row.original.submission_status === "incomplete" ? "" : "In review, make sure your samples loop,\nSubmitted\n" + moment.utc(
                    row.original.date_completed,
                    "DD-MM-YYYY HH:mm:ss"
                  ).tz("Asia/Kolkata").format("Do MMM YYYY, h:mm:ss a")}

                </div>
                <Button
                  onClick={() => {
                    getCollectedInfo(
                      row.original.submission_id,
                      row.original.submission_name
                    );
                  }}
                  type="submit"
                  className="submitmusic"
                  style={{ marginInline: "5px", marginBlock: "2vh", marginTop: "0px" }}
                >
                  Resume Editing
                </Button>
              </div>
            );
          } else if (row.original.csv_location.substring(0, 5) === "/bacu") {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                There might be a technical issue with your submission, please
                contact us.
              </div>
            );
          } else {
            let r = moment.utc(
              row.original.date_completed,
              "DD-MM-YYYY HH:mm:ss"
            );
            return (
              // <div
              //   className="data-input cell-padding d-flex cursor-default align-items-center flex-1"
              //   style={{ minHeight: "60px", display:  "flex" }}
              // >
              //   {" "}
              //   Submission Approved <br />
              //   {r.tz("Asia/Kolkata").format("dddd, Do MMM YYYY, h:mm:ss a")}
              // </div>
              <div
                style={{
                  minHeight: "60px",
                  display: "flex",
                  whiteSpace: "initial",
                  textAlign: "-webkit-left",
                  padding: "10px",
                }}>
                {"Submission Approved,\nSubmitted\n" + r.tz("Asia/Kolkata").format("Do MMM YYYY, h:mm:ss a")}

              </div>
            );
          }
        },
      },
      {
        id: 999995,
        minWidth: 65,
        dataType: DataTypes.STATIC,
        label: "Delete",
        Cell: ({ row }) => {
          return (
            // <div
            //   className="cell-padding"
            //   style={{  display:  "flex", paddingBlock:"25px" }}
            // >
            //   <Button
            //     onClick={() => {
            //       deleteSubmission(row.original.submission_id);
            //     }}
            //     type="submit"
            //     className="submitmusic"
            //     style={{marginTop: "0px",marginBottom:"0px", display: "flex"}}
            //   >
            //     Delete
            //   </Button>
            // </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  whiteSpace: "initial",
                  textAlign: "-webkit-left",
                  padding: "10px",
                }}>
              </div>
              <Button
                onClick={() => {
                  deleteSubmission(row.original.submission_id, row.original.submission_name);
                }}
                type="submit"
                className="submitred submitmusic"
                style={{ marginInline: "5px", marginBlock: "2vh", marginTop: "0px" }}
              >
                <DeleteIcon />
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const data = React.useMemo(() => {
    return userData;
  }, [userData]);

  return (
    <>
      <div className="profile-page-container">
        <h1>{user.nickname}'s submissions</h1>
        <h1>Total Completed Loops: {totalLoopCount}</h1>

        <div
          style={{ borderWidth: "1px", marginTop: "5vh" }}
          className="react-table-container"
        >
          {data && <ProfileTable columns={columns} data={data} />}
        </div>
      </div>
    </>
  );
};

export default Profile;
