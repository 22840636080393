import { useState, useEffect } from "react";
import "../App.css";
import { Button, Row, Col } from "react-bootstrap/";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import http from "../http-commons";
import { API, useInterval } from "../utils";
// import ReactTagInput from "@pathofdev/react-tag-input";
// import "@pathofdev/react-tag-input/build/index.css";
import { useAuth0 } from "@auth0/auth0-react";
import "./submitmusic.css";
import allcategories from "../TableUtils/allcategories.json";

const SubmitMusic = () => {

  const global_error_messages = {
    "mix_not_found": "A mixdown track is not provided. Use the Add Sample button at the bottom of the table to upload it",
    "master_not_found": "A master track is not provided. Use the Add Sample button at the bottom of the table to upload it",
    "text_not_found": "A textual description of the track is not provided",
    "midi_not_found": "Stems are not accompanied by a MIDI track - atleast 2 midi files per submission. Use the Add Sample button at the bottom of the table to upload more",
    "structure_not_found": "A .txt file with the track structure is not provided",
    "insufficient_instrument_roles": "There are not at least 3 instrumentation roles (melody, chords and bass). Use the Add Sample button at the bottom of the table to upload more",
    "insufficient_bass_variations": "bass has only one variation. Use the Add Sample button at the bottom of the table to upload more",
    "insufficient_chords_variations": "chords has only one variation. Use the Add Sample button at the bottom of the table to upload more",
    "insufficient_melody_variations": "melody has only one variation. Use the Add Sample button at the bottom of the table to upload more",
    "insufficient_percussion_variations": "percussion has only one variation. Use the Add Sample button at the bottom of the table to upload more",
    "insufficient_instruments": "The track has less than 5 different instruments, (e.g. Piano, Guitar, Synth, Double Bass, White noise fx, drums, tambourines, shakers). Use the Add Sample button at the bottom of the table to upload more",
    "insufficient_structure_lines": "The .txt file with the track structure is not structured properly",
    "insufficient_midi_files": "The number of midi files is less than 2. Use the Add Sample button at the bottom of the table to upload more",
    "too_many_txts": "More than one .txt file with the track structure is provided",
  }

  const { user } = useAuth0();

  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [formFilled, setFormFilled] = useState(false);
  const [viewprog, setViewprog] = useState(false);
  const [viewTonal, setViewTonal] = useState();
  const [viewGenre, setViewGenre] = useState();
  const [taskStatus, setTaskStatus] = useState();
  const [selectAll, setSelectAll] = useState(false);

  const initalSubmissionState = {
    genre: "",
    region: "",
    keyval: "",
    mood: "",
    tempo: "",
    keytagged: "",
    scale: "",
    time: "4/4",
    recording: "",
    samples: "",
    theme: "",
    submissionName: "",
    isStemSubmission: "",
    daw: "",
    isSingleGenre: "",
  }

  const initialChecklistState = {
    isOneTrackPerSubmission: "",
    is2min30sec: "",
    areStemsSameDuration: "",
    hasMidi: "",
    hasAtLeastThreeInstrumentRoles: "",
    hasAtLeastOneVariationsPerRole: "",
    eachVariationIsSeparateFile: "",
    hasAtLeast5Instruments: "",
    hasMixdownTrack: "",
    hasMasterTrack: "",
    hasStructureTxt: "",
  }

  const [progress, setProgress] = useState(0);
  const [taskId, setTaskId] = useState();
  const [total_files, setTotalFiles] = useState(0);
  const [files_processed, setFilesProcessed] = useState(0);
  const [submissionId, setSubmissionId] = useState();
  const [submissionDefaults, setDefaults] = useState(initalSubmissionState);
  const [checklistDefaults, setChecklistDefaults] = useState(initialChecklistState);

  // const [otherGenre, setOtherGenre] = useState([]);
  const availableGenres = allcategories.filter(col => col.id === "genre")[0].items;
  const availableMoods = allcategories.filter(col => col.id === "mood")[0].items;
  const availableDAWs = [
    "Ableton Live",
    "FL Studio",
    "Logic Pro",
    "Cubase",
    "Pro Tools",
    "Bitwig Studio",
    "Reason",
    "Reaper",
    "Renoise",
    "Studio One",
    "Other"
  ];
  const mandatory = ["submissionName", "daw", "recording", "isStemSubmission"]
  const mandatoryIfStem = ["mood", "genre", "theme"]

  const checklistForStems = ["hasStructureTxt", "isOneTrackPerSubmission", "is2min30sec",
    "areStemsSameDuration", "hasMidi", "hasAtLeast5Instruments", "hasAtLeastThreeInstrumentRoles",
    "hasAtLeastOneVariationsPerRole", "eachVariationIsSeparateFile", "hasMixdownTrack", "hasMasterTrack"]

  const checkFormFilled = () => {
    const { isStemSubmission, isSingleGenre } = submissionDefaults;
    if (isStemSubmission === "true") {
      const textFieldsOk = [...mandatory, ...mandatoryIfStem].every(field => submissionDefaults[field].trim() !== "")
        && checklistForStems.every(field => checklistDefaults[field].trim() === "yes");
      const tempoOk = submissionDefaults.tempo >= 60 && submissionDefaults.tempo <= 200;
      return textFieldsOk && tempoOk;
    } else {
      if (isSingleGenre === "true") {
        return [...mandatory, "genre"].every(field => submissionDefaults[field].trim() !== "")
      } else {
        return mandatory.every(field => submissionDefaults[field].trim() !== "")
      }
    }
  }

  const [submissionTask, setSubmissionTask] = useState({ df: [], tl: 0 });
  const controller = new AbortController();
  const signal = controller.signal;

  const [userSubmissionNames, setUserSubmissionNames] = useState([]);

  // useEffect(() => {
  //   if (viewGenre) {
  //     setDefaults({
  //       ...submissionDefaults,
  //       genre: otherGenre.toString(),
  //     });
  //   }
  // }, [otherGenre]);

  const { genre, region, scale, submissionName, isStemSubmission, theme } = submissionDefaults;
  let history = useHistory();

  const fetchNames = async (user) => {
    const response = await http.get(API.GET_USER_INFO + user.email).then(res => res.data.submissions.map(submission => submission.submission_name)).catch(err => []);
    return response;

  };
  useEffect(() => {
    fetchNames(user)
      .then(async (res) => {
        setUserSubmissionNames(res.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  useEffect(() => {
    setFormFilled(checkFormFilled());
  }, [submissionName, genre, region, scale, isStemSubmission, submissionDefaults, viewTonal, theme, selectAll]);

  const isNameUnique = () => {
    if (userSubmissionNames.includes(submissionName)) {
      alert(submissionName + " already exists, please choose another name or delete the existing one");
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    setDefaults({
      ...submissionDefaults,
      submissionName: isNameUnique() ? submissionName.trim().replace(/[^a-z0-9]/gi, '-') : "",
    });
  }, [submissionName]);

  const handleSelect = (e) => {
    const value = e.target.value;
    // if (value === "gothers" && e.target.name === "genre") {
    //   setViewGenre(true);
    // } else 
    if (e.target.name === "isSingleGenre") {
      setDefaults({
        ...submissionDefaults,
        genre: "",
        [e.target.name]: value,
      });
    }
    //   setViewGenre(false);
    //   setOtherGenre([]);
    //   setDefaults({
    //     ...submissionDefaults,
    //     genre: value,
    //   });
    // } 
    else {

      setDefaults({
        ...submissionDefaults,
        [e.target.name]: value,
      });

    }


  };

  const questions = {
    "hasStructureTxt": "Does the submission have a structure.txt file that describes the structure of your track?",
    "isOneTrackPerSubmission": "Are you uploading only one track per submission?",
    "is2min30sec": "Is the duration of the track 2 minutes and 30 seconds or more?",
    "areStemsSameDuration": "Are all the stems of the same duration?",
    "hasMidi": "Does the submission have at least 2 MIDI files?",
    "hasAtLeast5Instruments": "Does the track have at least 5 instruments?",
    "hasAtLeastThreeInstrumentRoles": "Does the track have at least 3 instrument roles?",
    "hasAtLeastOneVariationsPerRole": "Does the track have at least one variation per role?",
    "eachVariationIsSeparateFile": "Is each variation exported as a separate wav file?",
    "hasMixdownTrack": "Does the submission have a mixdown track?",
    "hasMasterTrack": "Does the submission have a master track?",
  }

  const CheckboxList = () => {
    const answers = checklistDefaults;
    const handleCheckboxChange = (e) => {
      const { name, checked } = e.target;
      if (name === 'selectAll') {
        setSelectAll(checked);
        const updatedAnswers = checklistDefaults;
        Object.keys(questions).forEach(questionKey => {
          updatedAnswers[questionKey] = checked ? "yes" : "no";
        });
        setChecklistDefaults(updatedAnswers);
      } else {
        const updatedAnswers = checklistDefaults;
        updatedAnswers[name] = checked ? "yes" : "no";

        setChecklistDefaults({
          ...checklistDefaults,
          [name]: checked ? "yes" : "no",
        });
        const allChecked = Object.keys(questions).every(questionKey => checklistDefaults[questionKey] === "yes");
        setSelectAll(allChecked);

      }
    };


    return (
      <div>
        <label
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            color: "white",
          }}
        >
          <h5 className="required-label">Stem Submission Checklist</h5>
          <input
            type="checkbox"
            name="selectAll"
            checked={selectAll}
            onChange={handleCheckboxChange}
          />
        </label>

        {Object.entries(questions).map(([key, question], index) => (
          <div
            key={key}>
            <label
              style={{
                // width: "100%",
                display: "flex",
                justifyContent: "space-between",
                color: "white",
                fontSize: "14pt",
              }}
            >
              {index + 1}. {question}
              <input
                type="checkbox"
                name={key}
                checked={answers[key] === "yes"}
                onChange={handleCheckboxChange}
              />
            </label>
          </div>
        ))}
      </div>
    );
  };

  const handleViewTonal = (e) => {
    const value = e.target.value;
    if (value === "yes") {
      setDefaults({
        ...submissionDefaults,
        keyval: "",
        keytagged: "true",
        scale: "",
        time: "",
      });
      setViewTonal(false);
    } else {
      setDefaults({
        ...submissionDefaults,
        keyval: "",
        keytagged: "false",
        scale: "",
        time: "",
      });
      setViewTonal(true);
    }
  };
  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
  };
  const upload = async (file, defaults, onUploadProgress) => {
    let formData = new FormData();
    formData.append("userEmail", user.email);
    formData.append("file", file);
    formData.append("defaults", JSON.stringify(defaults));
    let response = await http.post(API.FILE_UPLOAD, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
    return response;
  };

  useEffect(() => {
    if (viewprog) {
      window.scrollTo(0, 100000);
    }
  }, [viewprog]);
  const uploadhandler = async () => {
    setViewprog(true);

    let currentFile = selectedFiles[0];
    setProgress(0);
    setTaskStatus(null);
    upload(currentFile, submissionDefaults, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    }).then((response) => {
      setSubmissionId(response.data.submissionId);
      setTotalFiles(response.data.total_files);
      setTaskId(response.data.taskId);
    }).catch((error) => {
      let reason = error.response.data.reason || String(error);
      alert("Error uploading file, contact us if this persists\nReason: " + reason);
    });
  };

  useInterval(
    () => {

      let uid = btoa(user.email);
      http.get(API.TASK_STATUS + taskId + `?isStemSubmission=${submissionDefaults.isStemSubmission}&uid=${uid}`)
        .then((response) => {
          setTaskStatus(response.data.status);
          setFilesProcessed(
            Math.round((100 * response.data.meta.files_processed) / total_files)
          );
        })
        .catch((error) => {
          window.scrollTo(0, 0);
          setFilesProcessed(0);
          setProgress(0);
          setViewprog(false);
          setDefaults(initalSubmissionState);
          setFormFilled(false);
          setTaskId(null);
          setTaskStatus("failed");
          try {
            console.log(error.response.data);
            window.alert(`${error.response.data.reason}`);
            window.location.reload();
          } catch {
            window.alert("There is a problem with your submission, please try again later or contact support.");
            window.location.reload();
          }
        });
    },
    2000,
    (taskStatus === "completed" || (taskStatus !== "in progress" && !taskId)) || taskStatus === "failed"
  )

  const getCollectedInfo = async () => {
    let uid = btoa(user.email);
    let response = await http.get(API.TASK_STATUS + taskId + `?isStemSubmission=${submissionDefaults.isStemSubmission}&uid=${uid}`);
    if (response?.data.meta.error_messages) {
      localStorage.setItem(
        submissionId,
        JSON.stringify({
          df: JSON.parse(response.data.meta.df),
          tl: response.data.meta.tl,
          taskId: taskId,
          submissionId: submissionId,
          defaults: submissionDefaults,
          error_messages: response.data.meta.error_messages
        })
      );
      history.push("/submitmusic/collectedinfo", {
        df: submissionTask.df,
        tl: submissionTask.tl,
        taskId: taskId,
        submissionId: submissionId,
        submissionDefaults: submissionDefaults,
        error_messages: response.data.meta.error_messages
      });
    };
  };
  useEffect(() => {
    if (taskStatus === "completed") {
      setFilesProcessed(100);
      getCollectedInfo();
    }
  }, [taskStatus]);

  const handleSubmit = (e) => {
    let temp = submissionDefaults.theme.toString();
    let temp2 = submissionDefaults.genre.toString();
    setDefaults({
      ...submissionDefaults,
      theme: temp,
      genre: temp2,
    });
    e.preventDefault();
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  return (
    <div className="App">
      <div className="container">
        <div style={{ width: "inherit" }}>
          <div
            className="input"
            style={{
              marginTop: "10vh",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {" "}
            <h5 className="required-label">
              Please upload a .zip with your .wav samples.
            </h5>
            <div className="label" style={{ fontSize: "14pt" }}>
              Separate tags in your wav file names with any one of these delimiters <br />
              <span style={{ paddingInline: "5px" }}>Comma "," ;</span>
              <span style={{ paddingInline: "5px" }}>Hyphen "-" ;</span>
              <span style={{ paddingInline: "5px" }}>Underscore "_" ;</span>
              <span style={{ paddingInline: "5px" }}>Single Space " " ;</span>
            </div>
            <div style={{ fontSize: "14pt", paddingTop: "10px", color: "white" }}>Examples:
              <ul>
                <li style={{ paddingInline: "5px", color: "#31f080" }}> bass_loop_150bpm_Gm_tense.wav ✓ </li>
                <li style={{ paddingInline: "5px", color: "#31f080" }}> drum loop 130 happy.wav ✓ </li>
                <li style={{ paddingInline: "5px", color: "red" }}> melody120bpmsad.wav ❌ (no spaces between words used)</li>
                <li style={{ paddingInline: "5px", color: "red" }}> drums150_tense.wav ❌ (bpm 150 is not separated by any space)</li>
                <li style={{ paddingInline: "5px", color: "red" }}> drums 150-100 bpm/drums_150_tense.wav ❌ (mention the BPM only once) </li>
                <li style={{ paddingInline: "5px", color: "#31f080" }}> drums fast/drums_150_tense.wav ✓</li>
              </ul>

            </div>
            <div className="btn btn-default d-grid gap-2">
              <input
                id="fileup"
                type="file"
                accept=".zip, application/zip"
                onChange={selectFile}
                style={{ fontSize: "large" }}
              />
            </div>

          </div>

          <div>
            <Form onSubmit={handleSubmit}>
              {/* Submission Type */}
              <Row>
                <Form.Group
                  className="mb-3 submit-music-fix2"
                  controlId="submissssiontype"
                  required
                  name="submissionType"
                  value={submissionDefaults.isStemSubmission}
                  onChange={handleSelect}
                >
                  <Form.Label>
                    {" "}
                    <h5 className="required-label">
                      Submission Type (Stems or Loops)*
                    </h5>{" "}
                  </Form.Label>
                  <div key={`inline-radio`} className="mb-3 rdio">

                    <Form.Check
                      inline
                      label="Loops"
                      name="isStemSubmission"
                      type="radio"
                      value={false}
                      id={`loopr`}
                    />
                    <Form.Check
                      inline
                      required
                      label="Stems"
                      name="isStemSubmission"
                      type="radio"
                      value={true}
                      id={`stemr`}
                    />

                  </div>
                </Form.Group>
              </Row>

              {/* Submission Name */}
              <Row>
                <Form.Group
                  className="mb-3 submit-music-fix2"
                  controlId="submissssionName"
                >
                  <Form.Label>
                    {" "}
                    <h5 className="required-label"
                      // onClick={() => console.log(submissionDefaults, checklistDefaults, selectedFiles, checkFormFilled(), formFilled)}
                    >
                      Name your {`${submissionDefaults.isStemSubmission === "true" ? 'track' : 'submission'}`} (A-Z, a-z, 0-9, -)
                    </h5>{" "}
                  </Form.Label>
                  <Form.Control
                    placeholder="e.g. SynthPopChords, SarodLoops"
                    style={{
                      height: "40px",
                      width: "60vw",
                      textAlign: "center",
                    }}
                    name="submissionName"
                    value={submissionDefaults.submissionName}
                    onChange={handleSelect}
                  />
                </Form.Group>
              </Row>


              {/* Text Description */}
              {submissionDefaults.isStemSubmission === "true" && (
                <Row>
                  <Form.Group className="mb-3 submit-music-fix2" controlId="theme">
                    <Form.Label>
                      {" "}
                      <h5 className="required-label">
                        How would you describe your composition?
                      </h5>{" "}
                    </Form.Label>
                    <Form.Control
                      placeholder="A tense cinematic track from the 80s, a happy pop song, a sad melody, etc."
                      style={{
                        height: "40px",
                        width: "60vw",
                        textAlign: "center",
                      }}
                      name="theme"
                      value={submissionDefaults.theme}
                      onChange={handleSelect}
                    />

                  </Form.Group>
                </Row>
              )}

              {/* single genre or multiple genres */}
              {submissionDefaults.isStemSubmission === "false" && (
                <Row>
                  <Form.Group
                    className="mb-3 submit-music-fix2"
                    controlId="singlegenre"
                    required
                    name="singlegenre"
                    value={submissionDefaults.isSingleGenre}
                    onChange={handleSelect}
                  >
                    <Form.Label>
                      {" "}
                      <h5 className="required-label">
                        Are all the loops in your submission of a single genre?
                      </h5>{" "}
                    </Form.Label>
                    <div key={`inline-radio`} className="mb-3 rdio">

                      <Form.Check
                        inline
                        label="Yes"
                        name="isSingleGenre"
                        type="radio"
                        value={true}
                        id={`ysinglegen`}
                      />
                      <Form.Check
                        inline
                        required
                        label="No"
                        name="isSingleGenre"
                        type="radio"
                        value={false}
                        id={`nsinglegen`}
                      />

                    </div>
                  </Form.Group>
                </Row>
              )}

              {/* Genre */}
              {(submissionDefaults.isStemSubmission === "true" || submissionDefaults.isSingleGenre === "true") &&
                (<Row>
                  <Form.Group
                    className="mb-3 submit-music-fix2"
                    controlId="genre"
                    required
                    name="genre"
                    value={submissionDefaults.genre}
                    onChange={handleSelect}
                  >
                    <Form.Label>
                      <h5 className="required-label">
                        What genre is this submission in?*
                      </h5>
                      {/* <h6
                        style={{ color: "white", fontSize: "12pt" }}
                      >If you've already mentioned the genre in your filenames, pick the genre you want your the undetected files<br /> (i.e filenames with genres other than the ones listed below) to be tagged as
                      </h6> */}
                    </Form.Label>
                    <div key={`inline-radio`} className="mb-3 rdio">

                      {
                        availableGenres.map((genre) => (
                          <Form.Check
                            inline
                            required
                            label={genre}
                            name="genre"
                            value={genre}
                            type="radio"
                            id={`${genre}`}
                          />
                        ))
                      }

                    </div>

                  </Form.Group>
                </Row>)}

              {/* DAW */}
              <Row>
                <Form.Group
                  className="mb-3 submit-music-fix2"
                  controlId="daw"
                  required
                  name="daw"
                  value={submissionDefaults.daw}
                  onChange={handleSelect}
                >
                  <Form.Label>
                    <h5 className="required-label">
                      What DAW did you create this submission in?
                    </h5>
                    <h6
                      style={{ color: "white", fontSize: "12pt" }}
                    > If you've used multiple DAWs, pick the one you used the most during the creation of this submission
                    </h6>
                  </Form.Label>
                  <div key={`inline-radio`} className="mb-3 rdio">
                    {
                      availableDAWs.map((daw) => (
                        <Form.Check
                          inline
                          required
                          label={daw}
                          name="daw"
                          value={daw}
                          type="radio"
                          id={`${daw}`}
                        />
                      ))
                    }
                  </div>

                </Form.Group>
              </Row>

              {/* MIDI yes or no */}
              <Row>
                <Form.Group
                  className="mb-3 submit-music-fix2"
                  controlId="recording"
                  required
                  name="recording"
                  value={submissionDefaults.recording}
                  onChange={handleSelect}
                >
                  <Form.Label>
                    {" "}
                    <h5 className="required-label">
                      How was this submission recorded?
                    </h5>
                    <h6
                      style={{ color: "white", fontSize: "12pt" }}
                    >If you've used mostly MIDI files, pick "Programmed" else pick "Live"
                    </h6>
                    {" "}
                  </Form.Label>
                  <div key={`inline-radio`} className="mb-3 rdio">

                    <Form.Check
                      inline
                      label="Live"
                      name="recording"
                      type="radio"
                      value={"live"}
                      id={`liver`}
                    />
                    <Form.Check
                      inline
                      required
                      label="Programmed"
                      name="recording"
                      type="radio"
                      value={"Programmed"}
                      id={`progr`}
                    />

                  </div>
                </Form.Group>
              </Row>

              {/* Region */}
              {/* <Row>
                <Form.Group
                  className="mb-3 submit-music-fix2"
                  controlId="region"
                  required
                  name="region"
                  value={submissionDefaults.region}
                  onChange={handleSelect}
                >
                  <Form.Label>
                    <h5 className="required-label">
                      What music culture does this submission represent?*
                    </h5>
                  </Form.Label>

                  <div key={`inline-radio`} className="rdio mb-3">
                    <Form.Check
                      inline
                      required
                      label="Indian"
                      name="region"
                      type="radio"
                      value="indian"
                      id={`indianr`}
                    />
                    <Form.Check
                      inline
                      label="Western"
                      name="region"
                      type="radio"
                      value="western"
                      id={`western`}
                    />
                    <Form.Check
                      inline
                      label="South-Asian"
                      name="region"
                      type="radio"
                      value="south-asian"
                      id={`south-asian`}
                    />
                    <Form.Check
                      inline
                      label="African"
                      name="region"
                      type="radio"
                      value="african"
                      id={`african`}
                    />
                    <Form.Check
                      inline
                      label="Arabic"
                      name="region"
                      type="radio"
                      value="arabic"
                      id={`arabic`}
                    />
                  </div>
                </Form.Group>
              </Row> */}


              {/* Tonal Information */}
              {submissionDefaults.isStemSubmission === "true" && (
                <Row>
                  <Form.Group className="mb-3 submit-music-fix2">
                    <Form.Label>
                      <h5 className="required-label">
                        What key, scale and time signature is your track in?
                      </h5>
                      <h6
                        style={{ color: "white", fontSize: "12pt" }}
                      >(Pick the closest match if your track is not in a standard key, scale or time signature)</h6>
                    </Form.Label>

                  </Form.Group>

                  {true && (
                    <>
                      <Col style={{ display: "flex", justifyContent: "center" }}>
                        <Form.Group
                          className="mb-3"
                          controlId="key"
                          style={{
                            width: "70%",
                            minWidth: "150px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Form.Label>
                            <h6
                              className="required-label"
                              style={{
                                color: "white",
                                fontSize: "12pt",
                                marginBlock: "0px",
                              }}
                            >Key</h6>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            id={`key`}
                            required
                            name="keyval"
                            value={submissionDefaults.keyval}
                            onChange={handleSelect}
                          >
                            <option value="">Default</option>
                            <option value="c">C</option>
                            <option value="c#">C# / Db</option>
                            <option value="d">D</option>
                            <option value="d#">D# / Eb</option>
                            <option value="e">E</option>
                            <option value="f">F</option>
                            <option value="f#">F# / Gb</option>
                            <option value="g">G</option>
                            <option value="g#">G# / Ab</option>
                            <option value="a">A</option>
                            <option value="a#">A# / Bb</option>
                            <option value="b">B</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col style={{ display: "flex", justifyContent: "center" }}>
                        <Form.Group
                          className="mb-3"
                          controlId="scale"
                          style={{
                            width: "70%",
                            minWidth: "150px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Form.Label>
                            <h6
                              style={{
                                color: "white",
                                fontSize: "12pt",
                                marginBlock: "0px",
                              }}
                              className="required-label">Scale</h6>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            id={`scale`}
                            required
                            name="scale"
                            value={submissionDefaults.scale}
                            onChange={handleSelect}
                          >
                            <option value="maj">Major</option>
                            <option value="min">Minor</option>
                            <option value="">Other</option>
                            {/* {["Major",
                            "Minor",
                            "Dorian",
                            "Mixolydian",
                            "Lydian",
                            "Phrygian",
                            "Locrian",
                            "Bhairav",
                            "Whole Tone",
                            "Half-whole Dim.",
                            "Whole-half Dim.",
                            "Minor Blues",
                            "Minor Pentatonic",
                            "Major Pentatonic",
                            "Harmonic Minor",
                            "Harmonic Major",
                            "Dorian #4",
                            "Phrygian Dominant",
                            "Melodic Minor",
                            "Lydian Augmented",
                            "Lydian Dominant",
                            "Super Locrian",
                            "8-Tone Spanish",
                            "Hungarian Minor"
                          ].map((scale) => <option value={scale}>{scale}</option>)} */}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col style={{ display: "flex", justifyContent: "center" }}>
                        <Form.Group
                          className="mb-3"
                          controlId="timesig"
                          style={{
                            width: "70%",
                            minWidth: "150px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Form.Label>
                            <h6
                              style={{
                                color: "white",
                                fontSize: "12pt",
                                marginBlock: "0px",
                              }}
                              className="required-label">Time Signature</h6>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            id={`timesig`}
                            required
                            name="time"
                            value={submissionDefaults.time}
                            onChange={handleSelect}
                          >
                            <option value="4/4">4/4</option>
                            <option value="3/4">3/4</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </>
                  )}
                </Row>
              )}

              {submissionDefaults.isStemSubmission === "true" && (
                <>
                  {/* Mood */}
                  <Row>
                    <Form.Group
                      className="mb-3 submit-music-fix2"
                      controlId="mood"
                      required
                      name="mood"
                      value={submissionDefaults.mood}
                      onChange={handleSelect}
                    >
                      <Form.Label>
                        <h5 className="required-label">
                          What mood is this track in?*
                        </h5>
                      </Form.Label>
                      <div key={`inline-radio`} className="mb-3 rdio">
                        {
                          availableMoods.map((mood) => (
                            <Form.Check
                              inline
                              required
                              label={mood}
                              name="mood"
                              value={mood}
                              type="radio"
                              id={`${mood}`}
                            />
                          ))
                        }
                      </div>

                    </Form.Group>
                  </Row>
                  {/* Tempo */}
                  <Row>
                    <Form.Group className="mb-3 submit-music-fix2" controlId="theme">
                      <Form.Label>
                        {" "}
                        <h5 className="required-label">
                          What is the tempo of the stems?
                        </h5>{" "}
                      </Form.Label>

                      <Form.Control
                        placeholder="number between 60-200 BPM"
                        style={{
                          height: "40px",
                          width: "30vw",
                          textAlign: "center",
                        }}
                        name="tempo"
                        type="number"
                        min={60}
                        max={200}
                        value={submissionDefaults.tempo}
                        onChange={handleSelect}
                        onWheel={numberInputOnWheelPreventChange}
                      />

                    </Form.Group>
                  </Row>
                  <Row
                    style={{
                      width: "75%",
                      margin: "auto",
                    }}
                  >
                    <CheckboxList />
                  </Row>
                </>
              )}

              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  disabled={!(selectedFiles && formFilled)}
                  onClick={uploadhandler}
                  size="lg"
                  style={{ width: "50vw" }}
                  type="submit"
                  className="submitmusic"
                >
                  Submit
                </Button>
              </div>
            </Form>

            <div>
              {viewprog && (
                <>
                  <label className="loading-bar-label">File Upload</label>
                  <div
                    className="progress sp"
                    style={{ margin: "10px", minWidth: "30vw", height: "30px" }}
                  >
                    <div
                      className="progress-bar progress-bar-info progress-bar-striped"
                      role="progressbar"
                      aria-valuenow={progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{
                        width: progress + "%",
                        backgroundColor: "#31f080",
                        color: "#000",
                        fontSize: "1.1rem",
                      }}
                    >
                      {progress}%
                    </div>
                  </div>
                  <label className="loading-bar-label">
                    Sample Feature Extraction, Please Wait, This may take several minutes...
                  </label>
                  <div style={{ color: "white" }}>If you're stuck at this step, please drop us a message here: <a href="mailto: community@beatoven.ai?subject=About my submission&cc=syedali@beatoven.ai">community@beatoven.ai </a></div>
                  <div
                    className="progress sp"
                    style={{ marginBottom: "50px", minWidth: "30vw", height: "30px" }}
                  >
                    <div
                      className="progress-bar progress-bar-info progress-bar-striped"
                      role="progressbar"
                      aria-valuenow={files_processed}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{
                        width: files_processed + "%",
                        backgroundColor: "#31f080",
                        color: "#000",
                        fontSize: "1.1rem",
                      }}
                    >
                      {files_processed}%
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmitMusic;
