import React from 'react';

import Category from './Category.jsx';

import PropTypes from 'prop-types';

import createReactClass from 'create-react-class';

const Panel = createReactClass({
  propTypes: {
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    selection: PropTypes.object.isRequired,
    onAdd: PropTypes.func.isRequired,
    input: PropTypes.string.isRequired,
    addNew: PropTypes.bool,
    getTagStyle: PropTypes.func,
    getCreateNewText: PropTypes.func
  },

  getCategories() {
    return this.props.categories.map((c, i) => {
      return (
        <Category
          key={c.id}
          items={c.items}
          category={c.id}
          title={c.title}
          selected={this.props.selection.category === i}
          selectedItem={this.props.selection.item}
          input={this.props.input}
          addNew={this.props.addNew}
          type={c.type}
          onAdd={this.props.onAdd}
          single={c.single}
          getTagStyle={this.props.getTagStyle}
          getCreateNewText={this.props.getCreateNewText} />
      );
    });
  },

  render() {
    return (
      <div className='cti__panel'
        style={{
          position: 'relative',
        }}
      >
        <div className='cti__category__title'>
          <div style={{ fontSize: "small", fontWeight: "300" }}>Type to filter, press enter or tab to autocomplete</div>
          {this.props.id}
        </div>
        {this.getCategories()}
      </div>
    );
  }
});

export default Panel;
