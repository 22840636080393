import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Components/Home";
// import Login from "./Components/Login";
import Profile from "./Components/Profile";
// import Signup from "./Components/Signup";
import Thankyou from "./Components/Thankyou";
import SubmitMusic from "./Components/Submitmusic";
import CollectedInfo from "./Components/CollectedInfo";
// import EvaluateMusic from "./Components/EvaluateMusic";
import Navigation from "./Components/Navigation";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import CombinedInstructions from "./Components/CombinedInstructions";

const App = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Switch>
          <Route path="/" exact component={() => <Home />} />
          <Route path="/login" exact component={loginWithRedirect} />
          <Route
            path="/instructions"
            exact
            component={() => <>
              <CombinedInstructions />
            </>}
          />
          <Route
            path="/submitmusic"
            exact
            component={() => {
              return isAuthenticated ? <SubmitMusic /> : loginWithRedirect;
            }}
          />
          <Route
            path="/submitmusic/collectedinfo"
            exact
            component={() => {
              return isAuthenticated ? <CollectedInfo /> : loginWithRedirect;
            }}
          />
          <Route
            path="/submitmusic/thankyou"
            exact
            component={() => {
              return isAuthenticated ? <Thankyou /> : loginWithRedirect;
            }}
          />
          {/* 
          #TO DO: ADD EVALUATE MUSIC PAGE FUNCTIONALITY
          <Route
            path="/evaluatemusic"
            exact
            component={() => {
              return isAuthenticated ? <EvaluateMusic /> : loginWithRedirect;
            }}
          /> */}
          <Route
            path="/profile"
            exact
            component={() => {
              return isAuthenticated ? <Profile /> : loginWithRedirect;
            }}
          />
        </Switch>
      </Router>
    </div>
  );
};

export default App;